/* ----------------------------------------------------events type selections---------------------------------- */
.event-types-header-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: linear-gradient(to right, #009FFD, #045DE9);
}

.event-section-heading {
    font-size: 3rem;
    color: #fff;
    font-weight: 700;
    margin-bottom: 15px;
}

.event-types-Selection-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.event-type {
    background-color: white;
    color: #045DE9;
    border: 1px solid #045DE9;
    padding: 1rem 1.5rem;
    text-align: center;
    font-size: 1.3rem;
    margin: 4px 2px;
    border-radius: 5px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.event-type:hover {
    background-color: #008CBA;
    color: white;
}

.active-event {
    background-color: #008CBA;
    color: white;
}

/* -----------------------------------------------------------events filters options------------------------------------- */
.filters-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
}

.event-search-container {
    width: 80%;
}

.event-search-box {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: rgb(255, 255, 255);
    padding: 0 1rem;
    border-radius: 30px;
    font-size: 1.2rem;
}

.event-search-icon {
    font-size: 2rem;
    color: rgb(130, 130, 130);
}

.event-search-input {
    width: 100%;
    background: none;
    border: none;
}

.events-filter-container {
    position: relative;
}

.events-filter-sort-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 777;
    background-color: white;
    width: max-content;
}

.events-filter-sort-tooltip-option {
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.events-filter-sort-tooltip-option:hover {
    background-color: #b3b3b388;
}

.events-filter-text {
    font-size: 1.2rem;
    color: black;
    font-weight: 600;
    cursor: pointer;
}

/* -----------------------------------------------------event list-------------------------------------------------- */
.event-list-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.event-card {
    border-radius: 14px;
    background-color: #fff;
    box-shadow: 0 2px 9px 0 rgb(0 0 0 / 12%);
}

.event-card-image-container {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
}

.event-card-content-container {
    padding: 1rem;
}

.event-name-share {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;

}

.event-name-share a {
    text-decoration: none;
    color: black;
}

.event-share-icon {
    cursor: pointer;
}

.event-meta-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #a6a6a6;
    font-size: 1.1rem;
}

/* ----------------------------------------------------------------------show enent-------------------------------------------------- */
.show-event-section-container {
    background-color: #f0f4f3;
}

.show-event-section {
    padding-top: 4rem;
}

.back-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 0.8rem;
    color: rgb(2, 2, 27);
    background-color: rgba(0, 82, 99, .14);
    font-size: 1.5rem;
    border-radius: 15px;
}

.show-event-section-image-container {
    width: 100%;
    aspect-ratio: 3/1.5;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 2rem 0;
}

.show-event-section-title {
    font-size: 2rem;
    font-weight: 600;
    color: black;
    margin: 2rem 0;
}

.show-event-section-why-attend-text {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    margin: 1rem 0;
}

/* -----------------------------------------------------------------------media query----------------------------------------------------------------- */

/* =======================================================================event list================================================================= */
@media only screen and (max-width: 600px) {
    .event-list-container {
        grid-template-columns: 1fr;
    }

    .event-types-Selection-container {
        justify-content: space-between;
    }

    .event-type {
        padding: 0.5rem 1rem;
        font-size: 0.7rem;
        flex-grow: 1;
    }

    .event-type:hover {
        background-color: #008CBA;
        color: white;
    }

    .event-search-box {
        font-size: 1rem;
    }

    .event-search-icon {
        font-size: 1.5rem;
    }

    .events-filter-text {
        font-size: 0.8rem;
    }

    .events-filter-sort-tooltip {
        left: -30px;
    }
}