.Whyfintoo {
    padding: 4rem 0;
    background: #fff;
    width: 100%;
}

.WhyfintooBox {
    width: 65%;
    margin: auto;
}

.Whyfintoo .Whyfintootitle {
    font-size: 2.5rem;
    font-weight: 700;
    width: 98%;
    margin: auto;
    color: #252F4A;
}

.Whyfintooinfo {
    color: #4B5675;
    text-align: center;
    font-size: 1.375rem;
    width: 92%;
    margin: auto;
    color: #4B5675;
}

.WhyfintooCards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* width: 80%; */
    margin: auto;
}

.Whyfintoo .cardBox {
    flex: 1;
    align-items: center;
    border-radius: 9px;
    border: 0.75px solid rgba(46, 46, 31, 0.12);
    background: #FFF;
    box-shadow: 0px 30px 56px -18px rgba(36, 66, 59, 0.05);
    min-height: 350px;
}

.Whyfintoo .cardBox>div:first-child {
    display: flex;
    justify-content: center;
    min-height: 100px;
}

.Whyfintoo .cardBox .cardInfo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.Whyfintoo .cardBox .cardInfo div:first-child {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.6rem 0;
    color: #2E2E27;
}

.Whyfintoo .cardBox .cardInfo div:last-child {
    color: #4B5675;
}

/* Responsive  */

@media only screen and (max-width: 800px) {
    .Whyfintoo {
        padding: 4rem 2rem;
    }

    .WhyfintooBox {
        width: 100%;
    }

    .Whyfintoo .Whyfintootitle {
        font-size: 1.4rem;
        width: 100%;
    }

    .Whyfintooinfo {
        width: 100%;
        font-size: 1rem;
    }
    .WhyfintooCards{
        width: 100%;
    }
}