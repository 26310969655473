@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
.containerDemo {
  backface-visibility: hidden;
  background: linear-gradient(to right, #fff 0%, #fff 50%, #e6f2ff 50%, #e6f2ff 100%);
  border-radius: 22px;
  width: 1000px;
  height: 650px;
  box-shadow: -26px 0px 26px -24px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  /* background-image: url(./bg.png); */
  display: flex;
  perspective: 1000px;
}

.containerDemo .page {
  position: absolute;
  transform-style: preserve-3d;
  transition-property: all;
  width: 50%;
  height: 100%;
  left: 50%;
  transform-origin: left center;
  /* border-radius: 22px; */
}

.first,
.first .back {
  transform: rotateY(180deg);
}

.first {
  z-index: 102;
  transition: all 0.3s;
}

.second {
  z-index: 103;
  transition: transform 0.8s ease-in-out;
}

.third .logincontent {
  /* width: 400px; */
  width: 429px;
}

.fourth {
  z-index: 101;
  transition: all 0.3s;
}

.page>div,
.outer,
.logincontent,
.helper-class {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}

.page>div {
  width: 100%;
  transform-style: preserve-3d;
}

.back {
  transform: rotateY(180deg);
}

.outer {
  width: 100%;
  overflow: hidden;
  z-index: 999;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  background-color: #fff;
}

.back .outer {
  border-bottom-left-radius: 22px;
  border-top-left-radius: 22px;
}

.logincontent {
  width: 200%;
}

.front .logincontent {
  /* left: -100%; */
  left: -106%;
}

.front .logincontent img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.containerDemo .login-image {
  width: 140px !important;
}

.third .logincontent img {
  width: 250%;
  height: 100%;
  object-fit: cover;
}

.fourth .logincontent {
  padding-left: 100%;
}

.containerDemo .form-wrapper {
  width: 50%;
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 0rem 4rem;
}

.containerDemo input::placeholder {
  font-size: 1rem;
}

.containerDemo .heading {
  font-size: 2.2rem;
  font-weight: 600;
  font-family: "Ubuntu", sans-serif;
  padding: 0.6rem 0;
  color: #042b62;
}

.containerDemo .login-link {
  font-size: 14px;
  font-weight: 300;
  color: #808080;
  margin-bottom: 15px;
}

.containerDemo a {
  color: #5ea4ff;
  text-decoration: none;
}

.containerDemo .loginform {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerDemo .loginform .input:not(.checkbox) {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  font-size: 14px;
  margin: 1.5rem 0px .5rem 0;
}

.containerDemo .loginform label {
  width: 100%;
  font-size: 12px;
  line-height: 3;
}

.containerDemo .loginform input:not([type="checkbox"]) {
  display: block;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  font-size: 1.1rem;
  font-weight: 500;
  /* padding: 0 10px; */
}

.containerDemo .loginform ion-icon {
  font-size: 20px;
  color: #808080;
}

.containerDemo .loginform .input:not(.checkbox):focus-within {
  border-color: #d5d5d5;
}

.containerDemo .loginform .input:not(.checkbox).error {
  border-color: #ff5e5e;
}

.containerDemo .loginform .error-msg {
  color: #ff5e5e;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
  display: none;
}

.containerDemo .loginform .input.error~.error-msg {
  display: block;
}

.containerDemo .loginform ion-icon.eye {
  cursor: pointer;
}

.containerDemo .loginform .terms {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2rem 0;
}

.containerDemo .loginform .terms .error-msg {
  bottom: -10px;
}

.containerDemo .loginform .terms input[type="checkbox"] {
  cursor: pointer;
}

.loginpagebtn {
  /* background-color: #042b62; */
  color: #fff;
  padding: 15px;
  border: none;
  border-radius: 30px;
  margin: auto;
  cursor: pointer;
  width: 200px;
  margin: auto;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease-in-out;
}

.containerDemo .otpfields {
  margin: 1.3rem 0;
  display: flex;
  justify-content: center;

}

.containerDemo .otpfields input[type="text"] {
  width: 60px;
  height: 60px;
  border: 1px solid #ffff !important;
  -webkit-appearance: none;
  box-shadow: 0px 0px 2px 0px #b5afa1b8;
  -webkit-box-shadow :  0px 0px 2px 0px #b5afa1b8;
  border-radius: 6px;
  margin-left: 1.6rem;
  text-align: center;
  color: #000;
  font-size: 1.2rem;
  /* padding-left: 1rem; */
  background: transparent;
  font-weight: 600;
}

.containerDemo .otpfields input[type="text"]:first-child {
  margin-left: 0rem;
}

.otpfields input[type="text"]:focus {
  border-color: #042b62 !important;
  /* Warna orange saat focus */
}

.containerDemo .register-btn {
  /* margin: 0 2rem; */
  width: 200px;
  margin: auto;
}

.fourth .form-wrapper {
  width: 100%;
}

.hrline {
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
  margin: auto;
  padding: .4rem;
}

.containerDemo .loginotptimer {
  padding: 1.2rem 0;
}

.containerDemo .timecountdown {
  font-size: 1.2rem;
  font-weight: bold;
  color: #042b62;
}

.containerDemo .resendotpdiv {
  font-size: 1rem;
  font-weight: 600;
  color: #bbbbbbbf;
}

.containerDemo .otptxtinfo {
  padding: 0 3rem;
  font-size: 1rem;
  color: #bbbbbbbf;
  font-weight: 500;
  line-height: 1.8rem;
}

.containerDemo .otptxtinfo span {
  /* color: #042b62; */
  font-weight: 600;
}

.containerDemo input[type="email"] {
  font-size: 1.2rem;
}
/* .captchainput{
  border-bottom: 1px solid #cfcfcf !important;
  font-size: 1.8rem !important;
} */
@media (max-width: 1000px) {
  .containerDemo .form-wrapper {
    padding: 20px;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .login-demo {
    height: max-content;
    overflow: visible;
  }
  .captchainput{
    font-size: 1.3rem;
  }
  .containerDemo {
    width: 100%;
    height: 100vh;
    /* perspective: none; */
  }
  .containerDemo .heading {
    font-size: 1.3rem;
  }
  .containerDemo .page {
    width: 100%;
    height: 100%;
    left: 0;
    transition: all 0.3s;
    background-color: #fff;
    /* transform: rotateY(180deg); */
  }

  .containerDemo .outer {
    border-radius: 0;
  }

  .containerDemo .second {
    display: none !important;
  }

  .containerDemo .logincontent {
    /* width: 100%; */
    height: 100vh;
  }

  .containerDemo .login_form-wrapper {
    width: 100%;
  }

  .containerDemo .first,
  .first .back {
    transform: rotateY(0deg);
  }

  .back .logincontent {
    width: 100%;
  }

  .front .logincontent {
    left: -100%;
  }

  .containerDemo .otptxtinfo {
    padding: 0 1rem;
  }

  .containerDemo .otpfields input[type="text"] {
    width: 50px;
    height: 50px;
  }
 
}