
.ITRQueries {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 0px;
}
.ITRQueriesBox {
  display: flex;
  /* min-height: 10rem; */
}
.ImgBox {
  border: 1px solid #2575fc;
  padding: 1.4rem;
  border-radius: 50%;
  height: 83px;
}
.ImgBox svg {
  font-size: 3rem;
}
.SectionText {
  font-size: 1.3rem;
  font-weight: 400;
  color: #656565;
}
.ITRQueriesBox:hover .ImgBox {
  background-color: #2575fc;
  color: #fff;
}

.TextBox {
  margin-left: 1rem;
}
.SectionTitle {
  padding: 0px 0px 0px 0px;
  color: #2575fc;
  font-size: 1.4rem;
  font-weight: 600;
}
.SectionSubText {
  width: 80%;
  margin: auto;
  color: #86898b;
  font-size: 1.2rem;
  font-weight: 450;
}

@media only screen and (max-width: 600px) {
  .ITRQueries{
    width: 80%;
    display: block;
    
  }
  .ImgBox{
    height: 80px;
  }
  .ImgBox svg {
    font-size: 2rem;
  }
  .ITRQueriesBox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
  }
  .SectionTitle{
    font-size: 1.2rem;
  }
  .SectionText{
    font-size: 1rem;
  }
}

@media only screen and (max-width : 850px){
  .ITRQueries {
    width: 100%;
    padding: 2rem;
  }  
  .ImgBox{
    padding: 1rem;
    height: 63px;
  }
}