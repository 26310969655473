.header-section {
  position: relative;
  padding: 2rem 12rem;
  background-image: url("./images/IRRBG.png");
  background-position: bottom 51% right 15%;
  background-size: 36% auto;
  background-repeat: no-repeat;
}

.header-section-background-overlay {
  background-color: #ffffff;
  opacity: 0.94;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.header-section-title {
  color: #060606;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem;
}

.section-sub-title {
  margin-top: 2rem;
  color: #060606;
  font-size: 1.1rem;
  font-weight: 400;
}

.header-section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 10;
}

.header-section-content {
  width: 50%;
}

.header-section-content .sectionsubText {
  color: #060606;
  font-size: 1.1rem;
}

.header-section-content-btn {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #042b62;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #fcfcfc;
  border-radius: 30px;
  transition: all 0.3s;
  padding: 1rem 3rem;
}

.header-section-content-btn:hover {
  color: #042b62;
  background-color: rgba(44, 147, 245, 0);
  border-color: #042b62;
}

.header-section-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.header-section-image-container {
  width: 60%;
}

/* / / ---------------------------------------------------------------animations------------------------------------------------------------- / / */
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 300px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 100vh, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */
@media only screen and (max-width: 850px) {
  .header-section {
    padding: 2rem;
  }
  .header-section a {
    text-align: center;
  }
  .header-section-content-btn {
    width: 62%;
    text-align: center;
    margin: auto;
  }
  .header-section {
    padding: 2rem 2rem;
    background-image: url("./images/IRRBG.png");
    background-position: bottom 5% right 5%;
    background-size: 50% auto;
  }

  .header-section-image-container {
    width: 70%;
  } 
}

@media only screen and (max-width: 600px) {
  .header-section {
    padding: 2rem;
  }
  .header-section a {
    text-align: center;
    display: grid;
    text-decoration: none;
  }
  .header-section-content-btn {
    width: 62%;
    text-align: center;
    margin: auto;
  }
  .header-section-content {
    width: 100%;
  }

  .header-section-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .header-section-image-container {
    width: 95%;
  }

  .header-section-container {
    flex-direction: column-reverse;
  }

  .header-section {
    padding: 1rem;
    background-image: url("./images/IRRBG.png");
    background-position: top 35% right 0%;
    background-size: 100% auto;
  }

  .header-section-title {
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
  }

  .section-sub-title {
    color: #060606;
    font-size: 1rem;
    margin-top: 2rem;
    text-align: center;
  }
}

/* / -----------------------------------------------------------------------bounceInUp-------------------------------------------------------------- /  */
.animatedBouncInUp {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }

  80% {
    -moz-transform: translateY(10px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-ms-keyframes bounceInUp {
  0% {
    opacity: 0;
    -ms-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -ms-transform: translateY(-30px);
  }

  80% {
    -ms-transform: translateY(10px);
  }

  100% {
    -ms-transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }

  80% {
    -o-transform: translateY(10px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -ms-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
