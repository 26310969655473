.modalContent{
    width: 100%;
    display: flex;
    flex-direction:  column;
    align-items: center;
    justify-content: center;
}
.modalContent img{
    width: 250px;
}
 .infoText{
    font-size: 1.1rem;
    font-weight: 600;
    padding: 1rem 2rem;
    /* margin-top: 1rem; */
}