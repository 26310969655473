.btn1 {
    border-bottom: 1px solid #1a1a1a;
    border-top: 1px solid #1a1a1a;
    border-right: 1px solid #1a1a1a;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    /* transition: background 600ms ease, color 600ms ease; */
    overflow: hidden;
}
input[type="radio"].toggle {
    display: none;
}
input[type="radio"].toggle + label {
    cursor: pointer;
    min-width: 60px;
}
input[type="radio"].toggle + label:hover {
    background: none;
    color: #042b62;
}
input[type="radio"].toggle + label:after {
    background: #042b62;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
    width: 100%;
    z-index: -1;
    overflow: hidden;
}
input[type="radio"].toggle.toggle-left + label {
    border-right: 0;
    width: 11rem;
    border-left: 1px solid #1a1a1a;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    overflow: hidden;
}
input[type="radio"].toggle.toggle-left + label:after {
    left: 100%;
}
input[type="radio"].toggle.toggle-right + label {
    margin-left: -5px;
    width: 11rem;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
}
input[type="radio"].toggle.toggle-right + label:after {
    left: -100%;
    overflow: hidden;
}
input[type="radio"].toggle:checked + label {
    cursor: default;
    color: #fff;
    transition: color 200ms;
    overflow: hidden;
}
input[type="radio"].toggle:checked + label:after {
    left: 0;
    overflow: hidden;
}
