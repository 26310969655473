.section-title span {
    font-size: 3.4rem;
    font-weight: 700;
}

@media (max-width: 767px) {
    .section-title span {
        font-size: 1.5rem;
    }
    
}