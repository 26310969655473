.TeamGoalSection {
    background-color: #F1F9FD;
    text-align: center;
}

.TeamGoalSection p {
    color: black;
    text-align: center;
}

.GlobalText2 {
    padding: 2rem;
    color: black;
    font-size: 2rem;
    font-weight: bold;
}

.TeamMemberContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.TeamMemberElem {
    position: relative;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TeamMemberElem:hover .TeamMemberElemInfo {
    width: 100%;
    background-color: rgba(245, 245, 245, 0.97);
    color: black;
    transform: translateY(-90%);
}

.TeamMemberElemInfo {
    padding: 0.3rem 0;
    color: #f1f9fd00;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    transform: translateY(0%);

}

.TeamMemberElemInfo h4 {
    font-size: 1.4rem;
}

.TeamMemberElemInfo h5 {
    font-size: 1rem;
}

.TeamMemberElemImg {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease 0s;
    margin-top: -10px;
}

.TeamMemberElem:hover .TeamMemberElemImg {
    transform: scale(1.1);
}


@media only screen and (max-width: 850px) {
    .TeamMemberContainer {
        gap: 1.5rem;
    }

    .TeamMemberElem {
        width: 20%;
    }
}

@media only screen and (max-width: 600px) {
    .TeamMemberContainer {
        flex-wrap: wrap;
    }

    .TeamMemberElem {
        width: 70%;
    }
}