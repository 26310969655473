html.ITRPlanSection {
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
.ITRPlanSection{
    width: 80%;
    margin: auto;
    padding: 4rem 0rem;
}

.RightSection{
    
    background-color: #fafafe;
    padding: 2rem;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 0 #0000, 0 13px 22px rgba(70,74,85,.08);;
}
.PlanBox .planName{
    font-size: 1.2rem;
    font-weight: 600;
    max-width: 400px;
}
.PriceSection{
    /* font-size: 2.3rem; */
}
.LeftSection .planName {
    font-size: 2.3rem;
    font-weight: 700;
    color: #314259;
}
.LeftSection .planDes{
    color: #92959a;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 1rem 0;
}
.Reviews .reviewScore{
    font-size: 1.3rem;
    font-weight: 600;
    vertical-align: middle;
}
.Reviews svg{
    font-size: 1.4rem;
    vertical-align: sub;
    color: gold;
}
.LeftSection .planName {
    font-size: 2.3rem;
    font-weight: 700;
    color: #314259;
}
.planPrice{
    font-size: 2rem;
    font-weight: 700;
}
.PlanOffer .planpricemax{
    font-size: 1rem;
    font-weight: 500;
    text-decoration: line-through;
    color: #70757b;
}
.planOff{
    color: #e3757b;
    font-size: 1.2rem;
    font-weight: 700;
}
.PlanBuy{
    padding-top: 1rem;
}
.PlanBuy button{
    background-color: #042b62;
    color: #fff;
    font-weight: 600;
    font-size: 1.3rem;
    padding: .6rem 0rem;
    width: 90%;
    outline: none;
    border: none;
    border-radius: 12px;
}
@media (max-width: 767px) {
    .ITRPlanSection{
        padding: 1rem  0;
        margin: auto;
        width: 88%;
    }
    .LeftSection .planName{
        font-size: 1.8rem;
    }
    .LeftSection .planDes{
        font-size: 1rem;

    }
}
@media (min-width: 768px) {
    .RightSection {
        margin-left: 5rem;
    }
}