.mandateStatusbox {
    border: 1px solid rgba(0, 0, 0, 0.10);
    padding: 1.4rem 2rem;
    border-radius: 10px;
    margin-bottom: 1.3rem;
}

.infoHeaderContainer {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 1rem;
}

.infoContainer {
    display: grid;
    grid-template-columns: 60% 40%;
    border: 1px solid #efefef;
    border-radius: 5px;
}

.transactionInfoContainer {
    display: grid;
    grid-template-columns: 65% 35%;
    border-bottom: 1px solid #c7c8c9;
    padding: 1rem 0 1.5rem 0;
}

.transactionInfoContainerImgTitle {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.transactionInfoContainerImg {
    width: 10%;
}

.transactionInfoContainerTitle {
    font-weight: 500;
    font-size: 1.3rem;
}

.transactionInfoContainerAmountContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.transactionInfoContainerAmount {
    font-weight: bolder;
    font-size: 2rem;
}

.transactionInfoDataContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    border-bottom: 1px solid #c7c8c9;
    padding: 1.5rem 0;
}

.transactionInfoDataItemLeft {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1rem;
    padding: 0.5rem 0;
}

.transactionInfoDataItemRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 1rem;
    padding: 0.5rem 0;
}

.transactionInfoDataItemLabel {
    font-weight: 500;
    font-size: 1.1rem;
    color: grey;
}

.transactionInfoDataItemLabelVal {
    text-align: right;
    font-weight: 500;
    font-size: 1.1rem;
}

.transactionInfoDataFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0 0.5rem 0;
}

.transactionInfoStepContainer {
    padding: 1rem 1rem 1rem 2.5rem;
    display: flex;
    align-items: center;
}

/* ============================================================================== Step style =========================================================================== */

.stepContainer {
   width: 33.333%;
   /* margin: auto; */
}

.stepComponent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

/* For Mobile Responsive */


.stepText {
    font-weight: 500;
    font-size: 1.1rem;
    color: grey;
    /* padding-bottom: 1rem; */
}

.stepMessageContainer {
    width: 100%;
    color: black;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 2rem;
}

/* --------------------------------------------------------------------------------------------------------------------- */

.line {
    width: 0%;
    height: 100%;
    transition: width 1.5s;
    animation-name: animateWidth;
    animation-duration: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.bubble {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 999;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #D3D3D3;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.bubble:last-child {
    animation-iteration-count: infinite;
}

.bubble_content {
    position: absolute;
    font-size: 0.9rem;
    top: 8px;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 99;
    color: white;
    font-weight: normal;
}

.bubble_success_key_frame {
    animation-name: animateDot;
}

.bubble_pending_key_frame {
    animation-name: animateDotPending;
}

.bubble_failed_key_frame {
    animation-name: animateDotFailed;
}

.stepBankname {
    color: rgba(0, 0, 0, 0.50);
    ;
}

.investmoreBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.investmoreBtn button {
    background-color: #042b62;
    color: #fff;
    border: 0;
    outline: 0;
    padding: .5rem 1.4rem;
    font-weight: 500;
    border-radius: 20px;
}

/* ============================================================media queries=================================================================== */
@media only screen and (max-width: 850px) {
    .infoContainer {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 550px) {
    .line {
        width: 100%;
        height: 0%;
        transition: height 1.5s;
        animation-name: animateHeight;
        animation-duration: 2s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    .stepContainer {
        display: flex;
        gap: 2rem;
    }
    
    .stepElementsContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .transactionInfoContainer {
        grid-template-columns: 1fr;
    }

    .transactionInfoDataContainer {
        grid-template-columns: 1fr;
    }

    .transactionInfoDataItemLeft {
        margin-right: 0;
    }

    .transactionInfoDataItemRight {
        margin-left: 0;
    }

    .transactionInfoStepContainer {
        padding: 1rem;
    }

    .transactionInfoContainerAmount {
        font-size: 1.5rem;
    }

    .transactionInfoContainerTitle {
        font-size: 1.2rem;
        text-align: right;
        margin-bottom: 0.5rem;
    }

    .transactionInfoContainerImg {
        width: 40%;
    }

    .stepText {
        font-size: .9rem;
    }

    .stepBankname {
        font-size: .8rem;
    }

    .mandateStatusbox {
        padding: 1.4rem 0.6rem;
        font-size: .8rem;
    }
}

/* ============================================================================================================================================ */

@keyframes animateHeight {
    from {
        height: 0px;
    }

    to {
        height: 100%;
    }
}
@keyframes animateWidth {
    from {
        width:  0px;
    }

    to {
        width: 100%;
    }
}

@-webkit-keyframes animateDot {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.616);
        background: green;
        z-index: 9
    }

    30% {
        -webkit-box-shadow: 0 0 0 10px rgba(0, 128, 0, 0.303);
        background: green;
        z-index: 9
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        background: green;
        z-index: 9
    }
}

@keyframes animateDot {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.616);
        background: green;
        z-index: 9
    }

    30% {
        -webkit-box-shadow: 0 0 0 10px rgba(0, 128, 0, 0.303);
        background: green;
        z-index: 9
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 166, 11, 0);
        background: green;
        z-index: 9
    }
}

@keyframes animateDotFailed {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
        background: Red;
        z-index: 9
    }

    30% {
        -moz-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0.401);
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0.401);
        background: Red;
        z-index: 9
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
        background: Red;
        z-index: 9
    }
}

@keyframes animateDotPending {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 166, 0, 0.4);
        box-shadow: 0 0 0 0 rgba(255, 166, 0, 0.4);
        background: orange;
        z-index: 9
    }

    30% {
        -moz-box-shadow: 0 0 0 10px rgba(255, 170, 0, 0.481);
        box-shadow: 0 0 0 10px rgba(255, 170, 0, 0.481);
        background: orange;
        z-index: 9
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 162, 0, 0);
        box-shadow: 0 0 0 0 rgba(255, 162, 0, 0);
        background: orange;
        z-index: 9
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }

    30% {
        -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }

    30% {
        -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}