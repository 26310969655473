.filter{
    background-color: #fff;
    margin: 0rem !important;
    width: 100%;
    height: 100vh;
}
.inFilter {
    width: 100%;
    height: 100%;
    padding: 1.4rem;
}
.BigTextLabel {
    font-size: 0.9em;
    color: #000d44;
    font-weight: 700;
}
.filter input[type="checkbox"] {
    vertical-align: text-bottom;
    /* padding-top: 2.3rem; */
    width: auto;
    display: inline-block;
    color: #a1a1a1;
    border: 1px solid #e3e3e3;
    vertical-align: middle;
}
.SmallTextLabel input[type="checkbox"]{
    visibility: hidden;
}
.SmallTextLabel .reset {
    color: #a1a1a1;
    font-size: 0.7em;
    font-weight: 600;
    cursor: pointer;
}
.BigLabel {
    color: #000d44;
    font-size: 0.8em;
    font-weight: 700;
}
.filter .FilterCity {
    color: #a1a1a1;
    cursor: pointer;
    font-size: 0.8em;
    font-weight: 450;
}
.exFltr {
    width: 15%;
    padding-right: .75rem;
}
@media screen and (max-width : 768px){
    .filter{
        position: fixed;
        top: 0;
        z-index: 10000;
        left: -100vw;
        transition: .2s;
        width: 100vw;
        background-color: transparent;
        padding: 0px;
    }
    .inFilter {
        width: 80%;
        background-color: #fff;
    }
}