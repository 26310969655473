.Fhcheader {
  display: flex;
  justify-content: space-between;
  /* margin-top: 3rem; */
  align-items: center;
  left: 0;
  position: fixed;
  top: 0;
  padding: 2rem 10rem;
  transition: all .5s ease;
  width: 100%;
  z-index: 999;
margin-bottom: 4rem;
}

.subscribebtn {
  border-radius: 46.506px;
  border: 0.75px solid #042b62;
  padding: 0.6rem 2rem;
  background: transparent;
  color: #000;
  font-weight: 500;
  transition: 0.5s;
}
.subscribebtn:hover {
  background: #042b62;
  transition: 0.5s;
  color: #fff;
  border: 1px solid #042b62;
}
.fixedheader{
  background-color: #fff;
}
@media only screen and (max-width: 600px) {
  .Fhcheader img {
    width: 130px;
  }
  .subscribebtn {
   font-size: .9rem;
   padding: .4rem 1.3rem;
  }
}
