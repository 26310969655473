.expert-advisory-section {
    position: relative;
    padding: 2rem 12rem;
    /* background-image: url('./assets/insurance-1-expert-advisory-background.jpg'); */
    background-color: #9FDDF9;
    background-size: cover;
}

.expert-advisory-section-title {
    color: #060606;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3.5rem;
}

.expert-advisory-section-sub-title {
    color: #050404;
    /* font-family: "Poppins", Sans-serif; */
    font-size: 1.3rem;
    font-weight: 500;
    margin: 5px 0px 0px 0px;
    letter-spacing: 0.5px;
    margin: 1rem 0;
}

.expert-advisory-section-text {
    color: #151515;
    font-size: 1.1rem;
    margin: 15px 0px 0px 0px;
    line-height: 24px;
}

.expert-advisory-section-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    /* align-items: stretch; */
    margin: auto;
    position: relative;
    z-index: 10;
    /* padding: 3rem 0; */
}

.expert-advisory-section-content {
    width: 50%;
}

.expert-advisory-section-content .sectionsubText {
    color: #060606;
    font-size: 1.1rem;
}

.expert-advisory-section-content-btn {
    color: #FFFFFF;
    background-color: #042b62;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #042b62;
    border-radius: 5px;
    font-size: 15px;
    padding: 15px 30px;
    transition: all 0.3s;
    text-decoration: none;
}

.expert-advisory-section-content-btn:hover {
    color: #042b62;
    background-color: #FFFFFF;
    border-color: #042b62;
}

.expert-advisory-section-image-container {
    position: relative;
}

.front-img-container {
    position: absolute;
    top: -10%;
}

.front-img {
    animation: scaleAnime 1.5s alternate infinite ease-in;
}

/* / / ---------------------------------------------------------------animations------------------------------------------------------------- / / */

@keyframes scaleAnime {
    /* 0% {
        transform: scale(0);
    } */

    100% {
        transform: scale(1.058);
    }
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 300px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 300px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */

@media only screen and (max-width: 600px) {
    .expert-advisory-section {
        padding: 2rem 0.5rem;
    }

    .expert-advisory-section-content {
        width: 100%;
        text-align: center;
    }

    .expert-advisory-section-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .expert-advisory-section-image-container {
        width: 90%;
    }

    .expert-advisory-section-content-btn {
        width: 100%;
    }

    .expert-advisory-section-container {
        flex-direction: column-reverse;
    }
}

/* / -----------------------------------------------------------------------bounceInUp-------------------------------------------------------------- /  */
.animatedBouncInUp {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

@-webkit-keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes bounceInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateY(-30px);
    }

    80% {
        -moz-transform: translateY(10px);
    }

    100% {
        -moz-transform: translateY(0);
    }
}

@-ms-keyframes bounceInUp {
    0% {
        opacity: 0;
        -ms-transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -ms-transform: translateY(-30px);
    }

    80% {
        -ms-transform: translateY(10px);
    }

    100% {
        -ms-transform: translateY(0);
    }
}

@-o-keyframes bounceInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -o-transform: translateY(-30px);
    }

    80% {
        -o-transform: translateY(10px);
    }

    100% {
        -o-transform: translateY(0);
    }
}

@keyframes bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        transform: translateY(-30px);
    }

    80% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    -moz-animation-name: bounceInUp;
    -ms-animation-name: bounceInUp;
    -o-animation-name: bounceInUp;
    animation-name: bounceInUp;
}
@media only screen and (max-width : 850px){
    .expert-advisory-section{
        padding: 2rem 2rem;
    }
    .expert-advisory-section-content{
        width: 100%;
    }
}