@media (min-width: 1400px) {
  .container {
    width: 1140px !important;
  }
}

.SlideShow {
  display: flex;
  gap: 3rem;
}

.ReviewCard {
  display: grid;
  gap: 1.5rem;
  border-radius: 1rem;
  border: 1px solid #EEF2F6;
  background: #fff;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  padding: 2rem;
  margin-left: 1rem;
  width: 90%;
  margin-bottom: 3rem;
  height: 450px;
  position: relative;
  overflow: scroll;
  align-items: start;
  /* This ensures elements align properly */
}


.ReviewCard .ReviewTxt {
  font-size: 18px;
  text-align: justify;
}

.ReviewCard img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
}

.nextArrow,
.prevArrow {
  position: absolute;
  top: 103%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 0;
}

.nextArrow {
  right: 57%;
  /* Adjust this based on your layout */
}

.prevArrow {
  left: 57%;
  /* Adjust this based on your layout */
}

@media only screen and (max-width: 800px) {
  .ReviewCard {
    height: 400px;
  }

  .ReviewCard .ReviewTxt {
    font-size: 12px;
  }

  .nextArrow,
  .prevArrow {
    top: 111%;
    /* left: 57%; */
  }
  .nextArrow {
    right: 52%;
    /* Adjust this based on your layout */
  }
  .prevArrow {
    left: 52%;
    /* Adjust this based on your layout */
  }

  .nextArrow svg {
    width: 30px;
  }

  .prevArrow svg {
    width: 30px;
  }
}