.Membershiporg {
    margin: 5rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Membershiporgbox {
    display: flex;
    width: 360px;
    height: 237px;
    height: 237px;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    margin: 0rem 3rem;
    gap: 5px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(171, 190, 209, 0.20);
    text-align: center;
    font-size: 1rem;
    transition: .5s;
    /* cursor: pointer; */
}
.Membershiporgbox .Membershiporgboxes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 4px 30px 4px;
}
.Membershiporgbox::after {
    right: 0;
    bottom: 0;
    transition-duration: 0.4s;
}

.bgRect {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 50%;
    background: #EAF2F5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bgRect svg {
    position: relative;
    margin-top: 7px;
}

.Membershiporgbox .orgTitle {
    font-size: 1.7rem;
    font-weight: bold;
    /* padding-top: 1rem; */
}

.Membershiporgbox .orgDesc {
    color: #5B5B5B;
    font-weight: 400;
    font-size: 1.1rem;
}

.Membershiporgbox:hover {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.MembershiporgFirst .orgDesc {
    font-size: 1.3rem;
    font-weight: bold;
    /* color: #042b62; */
}

.MembershiporgFirst .orgTitle {
    font-size: 2.6rem;
    color: #042b62;
}

@media only screen and (max-width: 600px) {
    .Membershiporg {
        display: grid;
        gap: 2rem;
    }
}