@media (min-width: 1400px) {
    .container {
        width: 80%!important;
        margin: auto;
    }
}
.container {
    width: 80%!important;
    margin: auto;
}
.card {
    padding-top: 10px;
    padding-bottom: 10px;
}

.cardinner {
    padding: 10px;
    padding: 74px 3px 32px 18px;
}
.cardbox {
    padding: 0px 80px 0px 80px;
}
.cardcontainer {
    padding: 10px;
}
.cardheading {
    padding: 0px 0px 0px 0px;
    color: #042b62;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 400;
}
.cardparagraph {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    padding: 0rem 1rem 0rem 0rem;
    line-height: 26px;
}
.card-stock {
    background-image: url('./images/Stocks.svg');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
    background-color: #fff;
}
.card-IPO{
    background-image: url('./images/IPO.svg');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
    background-color: #fff;
}
.card-DMF{
    background-image: url('./images/Mutual_fund.svg');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
    background-color: #fff;
}
.card-bond{
    background-image: url('./images/Bonds.svg');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 150px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
    background-color: #fff;
}
.card-NRI{
    background-image: url('./images/NRI.svg');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
    background-color: #fff;
}
.cardextrapadding {
    margin-top: 70px;
}
.investsection {
    background-color: #042b621f;
    /* 042b6224 */
    background-image: url('./images/software_pattern__.png');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px 0px 30px 0px;
}
.section-title {
    color: #042b62;
    text-shadow: 0px 0px 0px rgb(0 0 0 / 30%);
    margin: 0px 0px 0px 0px;
    font-family: "Poppins", Sans-serif;
    font-size: 2.5rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 54px;
}
@media (max-width: 767px) {
    .container {
        width: 100%!important;
        margin: auto;
    }
    .cardextrapadding {
        margin-top: 0px;
    }
    .section-title {
        font-size: 1.5rem;
        text-align: center;
        line-height: 44px;
    }
    .card{
        margin: 0rem 1rem;
    }
    
}
.animated {
    animation-name: bounceInUp;
    animation-duration: 1s;
}
@keyframes bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px);
    }
    100% {
        transform: translateY(0);
    }
}