.TaxPricingContainer {
    width: 55%;
    margin: 0 auto
}

.TaxPricingMainContainer {
    background-color: #EDF9FF;
    border-radius: 10px;
    padding: 1rem 2rem 5rem 2rem;
}

.TaxPricingMainContainerTitle {
    color: #042b62;
    text-align: center;
    font-family: 'Red Hat Text', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 1rem;
}

.TaxPricingStepsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.7rem;
}

.TaxPricingStepItem {
    text-align: center;
    position: relative;
}

.TaxPricingStepItemImg {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TaxPricingStepItemConnector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TaxPricingStepsText {
    position: absolute;
    width: 150px;
    left: -140%;
    color: #263238;
    text-align: center;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 0.5rem;
    /* padding: 0 2rem; */
}

.TaxPricingContentContainer {
    padding: 2rem 3rem;
}

.pricingBoxPointsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.pricingBoxPoint {
    width: 33.33%;
    margin: 0.4rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.1rem;
}

.pricingBoxPointPadding {
    padding-left: 3rem
}

@media only screen and (max-width: 850px) {
    .TaxPricingContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .TaxPricingContainer {
        width: 95%;
    }

    .TaxPricingContentContainer {
        padding: 2rem 1rem;
    }

    .pricingBoxPoint {
        width: 100%;
    }

    .pricingBoxPointPadding {
        padding-left: 0
    }

    .TaxPricingStepsContainer {
        overflow-x: scroll;
        justify-content: start;
        height: 100px;
        padding-left: 3rem;
    }

    .TaxPricingMainContainer {
        padding: 1rem;
    }
}