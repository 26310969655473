
.calender {
    position: relative;
    min-width: 320px;
    height: 630px;
}
.section {
    background-color: #ffff;
}
.container {
    max-width: 1140px;
}
.container h2{
    font-size: 2.5rem;
    font-weight: 700;
}
.container h3{
    font-size: 1.6rem;
    font-weight: 400;
}