.TextLabel{
    width: 80%;
    margin: auto;
    text-align: center;
    color: #86898b;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
}
.tabSection{
    width: 100%;
    height: 100%;
}
.tabData{
    border: 1px solid #042b62;
}
.links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #042b62;
    /* padding: 0 2rem; */
}
.tabName div{
    padding: 1rem 2rem;
    color: #ffff;
    font-size: 1.1rem;   
    font-weight: 700; 
}
.links  .tabName:hover{
    background-color: #ffff;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.links  .tabName:hover ~ div{
    color: #0000;
}
.tabSection{
    padding: 2rem;
    color: #181717;
    font-size: 1.3rem;
    font-weight: 400;
    transition: .2s ease-in-out;
}
@media only screen and (max-width: 600px) {
    .tabSection{
        padding: 1rem;
    }
    .tabSection
    .tabName{
        white-space: nowrap;
        padding: 0rem;
        font-size: 1rem;
    }
    .links{
        overflow-x: auto;
    }
    .TextHeader{
        font-size: 1.2rem;
        padding: 1rem 0rem;
        line-height: 1.7rem;
    }
    .TextLabel{
        width: 100%;
        padding: 1rem;
    }
    .conetntSlide{
        padding: 1rem;
    }
    .borderBottom{
        border-bottom: 2px solid #042b62;
    }
}
