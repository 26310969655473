.PortfolioReviewSection {
    background-color: #EBF9FE;
    position: relative;
    z-index: 99;
    padding-bottom: 2rem;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.GlobalText {
    padding: 2rem;
    color: #ffffff;
    font-size: 2rem;
    font-weight: bold;
}

.GlobalText2 {
    padding: 2rem;
    color: black;
    font-size: 2rem;
    font-weight: bold;
}

.Text1 {
    font-size: 1.3rem;
    font-weight: 600
}

.Text2 {
    font-size: 1.5rem;
    font-weight: 600
}

.btn1 {
    width: fit-content;
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    border-radius: 7px;
    color: white;
    background-color: #1B84FF;
    cursor: pointer;
    font-weight: 700;
}

.btn2 {
    width: fit-content;
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    border-radius: 7px;
    color: #1B84FF;
    background-color: #E9F3FF;
    cursor: pointer;
    font-weight: 700;
}

.btnDisabled {
    width: fit-content;
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    border-radius: 7px;
    color: white;
    background-color: #DFDFDF;
    cursor: pointer;
    pointer-events: none;
    font-weight: 700;
}

.PortfolioReviewSectionTabContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 2.5rem 0;
}

.PortfolioReviewSectionTab {
    font-size: 1.5rem;
    font-weight: 600;
}

.PortfolioReviewSectionTabActive {
    color: #1B84FF;
    text-decoration: underline;
    text-underline-offset: 10px;
}

.PortfolioReviewSectionStepsContainer {
    width: 42%;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
}

.BasicInfoStepIndicatorContainer {
    width: 50%;
    margin: 0 auto;
    text-align: center;
}

.BasicInfoStepIndicatorBar {
    width: 100%;
    height: 15px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    background-color: #ffffff;
    border-radius: 30px;
    margin: 0.5rem 0;
}

.BasicInfoStepIndicatorInnerBar {
    height: 100%;
    background-color: #1B84FF;
    border-radius: 30px;
    transition: all 1s linear;
}

.TwoInputsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
}

.InputsContainer {
    width: 100%;
}

.InputContainer {
    width: 100%;
}

.InputContainer input {
    width: 100%;
    border: #cccccc 1px solid;
    border-radius: 7px;
    font-size: 1.1rem;
    padding: 0.6rem;
}

.InputContainer label {
    font-size: 1rem;
    color: #000000;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.otpfields {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    /* justify-content: space-between; */

}

.otpfields input[type="text"],
.otpfields input[type="number"] {
    width: 40px;
    height: 40px;

    border-bottom: 2px solid #000000;
    border-radius: 0;

    font-weight: 600;
    text-align: center;
}

.otpfields input[type="text"]:first-child {
    margin-left: 0rem;
}

.otpfields input[type="text"]:focus {
    border-bottom: 2px solid #042b62 !important;
}

.otpfieldsContainer {
    width: 100%;
    border-radius: 7px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 0 1.5rem; */
}

.ReviewBuildIndicatorContainer {
    width: 53%;
    aspect-ratio: 1;
    margin: 0 auto;
    background-color: white;
    /* display: grid;
    grid-template-rows: 60% 40%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    box-shadow: 0px 1px 8px 0px #00000014;
    border-radius: 7px;
}

.ConnectWithExpertContainer .calendly-inline-widget {
    height: 1000px !important;
}

.BottomAlertInfo {
    background-color: #0066DF;
    padding: 1rem 6rem;
    border-radius: 15px 15px 0 0;
    color: white;
    width: fit-content;
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    font-weight: 600;
}

.BottomAlertError {
    background-color: #FFB500;
    padding: 1rem 6rem;
    border-radius: 15px 15px 0 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    width: fit-content;
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    border-top: 2px solid rgb(212, 95, 0);
    border-right: 2px solid rgb(212, 95, 0);
    border-left: 2px solid rgb(212, 95, 0);
}

.BottomAlertSuccess {
    background-color: green;
    padding: 1rem 6rem;
    border-radius: 15px 15px 0 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    width: fit-content;
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
}

@media only screen and (max-width: 850px) {
    .otpfields input[type="text"] {
        width: 45px;
        height: 45px;
    }

    .PortfolioReviewSectionStepsContainer {
        width: 60%;
        margin: 0 auto;
    }

    .ReviewBuildIndicatorContainer {
        width: 80%;
    }
}

@media only screen and (max-width: 600px) {

    .GlobalText {
        font-size: 1.5rem;
    }

    .GlobalText2 {
        font-size: 1.5rem;
    }

    .PortfolioReviewSectionStepsContainer {
        width: 95%;
        margin: 0 auto;
        padding: 1rem;
    }

    .PortfolioReviewSectionTabContainer {
        gap: 0.5rem;
    }

    .PortfolioReviewSectionTab {
        font-size: 1rem;
    }

    .ReviewBuildIndicatorContainer {
        width: 100%;
        grid-template-rows: 1fr 1fr;
    }

    .BasicInfoStepIndicatorContainer {
        width: 90%;
    }

    .TwoInputsContainer {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .otpfieldsContainer {
        flex-wrap: wrap;
        padding-bottom: 1rem;
    }

    .BottomAlertInfo {
        padding: 1rem 2rem;
        width: 95%;
        font-size: 1rem;
    }

    .BottomAlertError {
        padding: 1rem 2rem;
        width: 95%;
        font-size: 1rem;
    }

    .BottomAlertSuccess {
        padding: 1rem 2rem;
        width: 95%;
        font-size: 1rem;
    }
}