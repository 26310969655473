.ExtraPerformanceAdviceSection {
    background-color: #00132E;
    text-align: center;
}

.ExtraPerformanceAdviceSection p {
    color: white;
}

.GlobalText2 {
    padding: 2rem;
    color: white;
    font-size: 2rem;
    font-weight: bold;
}

@media (max-width: 850px) {
    .ExtraPerformanceAdviceSection img{
        width: 80%;
    }
}