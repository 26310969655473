.imagebox {
    background-color: #042b62;
    /* padding: 10px; */
    border-radius: 50%;
    overflow: hidden;
    width: 20px;
    height: 20px;
}
.imagebox img {
    width: 100%;
    height: 100%;
    margin-top: -4px;
}
.chooseText {
    color: #042b62;
    font-weight: 600;
    padding-left: 15px;
}
.buttonChoose {
    border: 2px solid #042b62;
    padding: 5px 10px;
    border-radius: 20px;
}
.documentDropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    border-bottom: 1px solid #000;
    padding: 6px;
}
.drpTitle {
    margin: 0px;
}
.trField td {
    padding-top: 20px;
    padding-bottom: 20px;
}
.addMoreBtn {
    color: #042b62;
    margin-bottom: 0px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}
.textarea {
    width: 100%;
    border-radius: 12px;
    padding: 20px;
}
.btn {
    margin: auto;
    border-radius: 20px;
    background: #042b62;
    color: #fff;
    border: 0px;
    font-weight: 600;
    display: block;
    padding: 10px 20px;
}

.btnOutline {
    margin: auto;
    border-radius: 20px;
    background: white;
    border: 2px solid #042b62;
    color: #042b62;
    /* border: 0px; */
    font-weight: 600;
    display: block;
    padding: 10px 20px;
}

.btn_skip {
    /* margin: auto; */
    /* border-radius: 20px; */
    /* background: #042b62; */
    color: #000000;
    /* border: 0px; */
    font-weight: 600;
    /* display: block; */
    /* padding: 05px 15px; */
}

.subtitle {
    font-size: 1.2rem;
    color: #888;
}

.buttonChoose input[type="file"] {
    /* display: none; */
}

.buttonChoose label {
    height: 20px;
    /* width: 100%; */
}

/* .CustomFile{
    color: transparent;
} */
.preview-link {
    text-decoration: none;
    color: #042b62;
    cursor: pointer;
}

.preview-link:hover {
    color: #000 !important;
}

/* Modal */

.otptext {
    font-size: 1.4rem;
    border: 0px;
    border-bottom: 1px solid;
    text-align: center;

    margin: auto;
    display: block;
    padding-top: 1rem;
    margin-bottom: 1rem;

}

.long-btn {
    background-color: #042b62;
    border: 1px solid #042b62;
    border-radius: 15px;
    color: #fff;
    height: 2em;
    line-height: 2em;
    text-align: center;
    width: 35%;
    /* float: right; */
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
}

.modal-cntn {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.lbltext {
    text-align: center;
    font-size: 1.4rem;
}

.p-1 {
    font-size: 1.4rem;
    font-weight: 500;
}

.p-2 {
    font-size: 1.3rem;
    font-weight: 500;
}

.enterbox {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.ecntRItems {
    width: 14rem;
}

.borderSpace {
    font-size: 0.9rem;
    color: #7f7f7f;
    padding-bottom: 0.8rem;
    font-weight: 500;
}

.extraSpace {
    padding-left: 2rem;
}

.newwidth {
    width: 30%;
}

.newwidth {
    width: 5% !important;
}

.policyCompany {
    width: 16% !important;
}

.policyNumber {
    width: 19% !important;
}

.smallfont {
    font-size: .8rem;
    color: #7f7f7f;
}

.trash {
    color: #042b62;
}

.trash:hover {
    cursor: pointer;
}

/* // d-flex align-items-center justify-content-between */

.fltTrx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.subTiles {
    display: flex;
    flex-direction: column;
}

.back-bt {
    color: #042b62 !important;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 1rem;
}

.addBtn {
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

.addBtnContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.linkStyle {
    text-decoration: none;
}

.realEstateTable th:nth-child(1),
.realEstateTable td:nth-child(1) {
    /* width: 15%; */
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.realEstateTable tr {
    width: 100%;
}

.realEstateTable th:nth-child(2),
.realEstateTable td:nth-child(2) {
    width: 10%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.realEstateTable th:nth-child(3),
.realEstateTable td:nth-child(3) {
    width: 10%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.realEstateTable th:nth-child(4),
.realEstateTable td:nth-child(4) {
    width: 10%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.realEstateTable th:nth-child(5),
.realEstateTable td:nth-child(5) {
    width: 10%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.realEstateTable th:nth-child(6),
.realEstateTable td:nth-child(6) {
    width: 10%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.realEstateTable th:nth-child(7),
.realEstateTable td:nth-child(7) {
    width: 10%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.actionBtnsContainer {
    display: flex;
    align-items: center;
    gap: 2rem
}


.statsContainer {
    /* display: grid;
    grid-template-columns: max-content 1fr;
    gap: 2rem; */
    /* display: flex; */
}

.portfolioValue {
    /* width: 100%; */
}

.statsData {
    /* width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; */
}

.statsData::-webkit-scrollbar {
    display: none;
}

.portfolio-insurance-table tr td:first-child {
    width: 16% !important;
    word-wrap: break-word;
    white-space: normal;
}

.portfolio-insurance-table tr td:nth-child(2),
.portfolio-insurance-table tr td:nth-child(3),
.portfolio-insurance-table tr td:nth-child(4),
.portfolio-insurance-table tr td:nth-child(5),
.portfolio-insurance-table tr td:nth-child(6),
.portfolio-insurance-table tr td:nth-child(7) {
    width: 12.5% !important;
}

.portfolio-insurance-table tr td:nth-child(8) {
    width: 9% !important;
}

.portfolio-insurance-table tr td:first-child strong {
    width: 13rem !important;
    display: block;
}

.portfolio-insurance-table tr:last-child td,
.portfolio-fd-table tr:last-child td,
.realEstateTable tr:last-child td,
.liquid-table tr:last-child td,
.mfTable tr:last-child td,
.alternateTbl tr:last-child td,
.stockTbl tr:last-child td,
.govtSchemeTbl tr:last-child td,
.goldTbl tr:last-child td,
.otherInvTbl tr:last-child td {
    border-bottom-width: 0px;
}

.portfolio-fd-table tr td:nth-child(3) {
    width: 9.11%;
}

.portfolio-fd-table tr td:nth-child(4) {
    width: 14.11%;
}

.portfolio-fd-table tr td:nth-child(1),
.portfolio-fd-table tr td:nth-child(2),
.portfolio-fd-table tr td:nth-child(5),
.portfolio-fd-table tr td:nth-child(6),
.portfolio-fd-table tr td:nth-child(7),
.portfolio-fd-table tr td:nth-child(8),
.portfolio-fd-table tr td:nth-child(9) {
    width: 11.11%
}

.bold-table tr td {
    font-weight: bold;
    color: #8d8d8d;
}

.realEstateTable tr td:nth-child(1),
.realEstateTable tr td:nth-child(2),
.realEstateTable tr td:nth-child(3),
.realEstateTable tr td:nth-child(4),
.realEstateTable tr td:nth-child(5),
.realEstateTable tr td:nth-child(6),
.realEstateTable tr td:nth-child(7) {
    width: 14.28%;
}

.goldTbl tr td:nth-child(2),
.goldTbl tr td:nth-child(3),
.goldTbl tr td:nth-child(4),
.goldTbl tr td:nth-child(5) {
    width: 16.66%;
}

.goldTbl tr td:nth-child(1) {
    width: 20.36%;
}

.goldTbl tr td:nth-child(6) {
    width: 13%;
}


.liquid-table tr td:nth-child(1),
.liquid-table tr td:nth-child(2),
.liquid-table tr td:nth-child(3) {
    width: 33.33%;
}

.mfTable tr td:nth-child(1) {
    width: 33%;
}

.mfTable tr td:nth-child(2) {
    width: 14.5%;
}

.mfTable tr td:nth-child(3) {
    width: 14.5%;
}

.mfTable tr td:nth-child(7) {
    width: 7%;
}

.mfTable tr td:nth-child(4),
.mfTable tr td:nth-child(5),
.mfTable tr td:nth-child(6) {
    width: 10%;
}

.alternateTbl tr td:nth-child(2),
.alternateTbl tr td:nth-child(3),
.alternateTbl tr td:nth-child(4),
.alternateTbl tr td:nth-child(5) {
    width: 18%;
}

.alternateTbl tr td:nth-child(1) {
    width: 20%;
}

.alternateTbl tr td:nth-child(6) {
    width: 8%;
}

.stockTbl tr td:nth-child(2),
.stockTbl tr td:nth-child(3),
.stockTbl tr td:nth-child(4),
.stockTbl tr td:nth-child(5),
.stockTbl tr td:nth-child(6) {
    width: 11%;
}

.stockTbl tr td:nth-child(1) {
    width: 38%;
}

.stockTbl tr td:nth-child(7) {
    width: 7%;
}

.stockTbl tr td:nth-child(9) {
    width: 7%;
}

.govtSchemeTbl tr td:nth-child(1) {
    width: 16%;
}

.govtSchemeTbl tr td:nth-child(2),
.govtSchemeTbl tr td:nth-child(3),
.govtSchemeTbl tr td:nth-child(4),
.govtSchemeTbl tr td:nth-child(5),
.govtSchemeTbl tr td:nth-child(6), 
.govtSchemeTbl tr td:nth-child(7),
.govtSchemeTbl tr td:nth-child(8) {
    width: 11%;
}

.otherInvTbl tr td:nth-child(1) {
    width: 21%;
}
.otherInvTbl tr td:nth-child(2),
.otherInvTbl tr td:nth-child(3),
.otherInvTbl tr td:nth-child(4),
.otherInvTbl tr td:nth-child(5) {
    width: 18%;
}
.otherInvTbl tr td:nth-child(6) {
    width: 7%;
}

.headerTable td {
    font-size: .95rem;
}
.dataTable td {
    font-size: .9rem;
    font-weight: 500;
}
.tblIcons {
    width: 15px;
    height: 15px;
}

.PaymentTitle {
    padding-left: 2rem;
}

.stepContainer {
    padding: 8rem 0 1rem 14rem;
}

.stepComponentContainer {
    width: 60%;
    margin: 2rem 0 4rem 4rem
}

@media (max-width:900px) {
.stepComponentContainer {
        width: 80%;
        margin: 2rem 0 4rem 4rem
    }

    .stepContainer {
        padding: 8rem 3rem;
    }

    .wrapBtns {
        flex-wrap: wrap !important;
    }

    .PaymentTitle {
        padding-left: 5rem;
    }
}

@media (min-width: 768px) {
    .fltTrx {
        flex-direction: row;
    }

    .subTiles {
        flex-direction: row;
    }
}

.flexBxAssetName {
    justify-content: end;
}
@media (min-width: 768px) {
    .flexBxAssetName {
        justify-content: start;
    }
  
}
@media (max-width: 500px) {
.stepComponentContainer {
        width: 100%;
        margin: 0.5rem;
    }

    .stepContainer {
        padding: 0.5rem;
    }

    .PaymentTitle {
        padding-left: 0;
    }

    .statsContainer {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    
    .ecntRItems {
        width: 100%;
    }

    .portfolioValue {
        width: 100%;
    }

    .statsData {
        width: 100%;
    }

    .policyCompany {
        width: 100% !important;
    }

    .dataTable td {
        width: 100% !important;
    }
    
    .liquidopt {
        justify-content: left;
        display: flex;
    }
    
    .liquidTable td:first-child {
        width: 100%;
    }
    
    .extraSpace {
        padding-left: 0;
    }
}

.fund-tick-active {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #80ed99;
    float: left;
    margin-right: 5px;
}

.fund-tick-inactive {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #FFA500;
    float: left;
    margin-right: 5px;
}
.fund-tick-external {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    float: left;
    margin-right: 5px;
    position: relative;
    border: 1px solid #000;
}
.fund-tick-external::before {
    content: "E";
    position: absolute;
    font-size: 10px;
    left: 0px;
    line-height: 12px;
}

/* DG Modal */
.modal-cntn-www {
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding: 3rem;
}

.OK-btns {
    background: transparent;
    border: 1px solid #042b62;
    color: #042b62;
    padding: 6px 28px;
    border-radius: 30px;
    outline: none;
    font-weight: 600;
}

.OK-btns:hover {
    color: #fff;
    background: #042b62;
}

.linkmodaltext {
    font-size: 1rem;
    white-space: nowrap;
    font-weight: bold;
    text-align: center;
    padding: 1rem 0;
}

.linkmodalDestext {
    font-size: .9rem;
    text-align: center;
    opacity: .7;
    color: #000;
}