.land-txt {
  top: 9rem;
  color: #fff;
}

.videobox {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.h1 {
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
  margin-top: 11rem;
 
}

.SpaceReduce {
  color: #ffffff;
  font-size: 4.3rem;
  font-weight: bold;
  margin-top: 8rem;
  text-transform: capitalize;
}

.land-txt p {
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2em;
}

.TextHello {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.vBox {
  position: absolute;
}

.mouseicon {
  width: 25px;
  height: 45px;
  border-radius: 15px;
  position: relative;
  border: 2px solid #dedede;
  text-align: center;
  cursor: pointer;
  margin-top: 4rem;
}

.mouseicon span {
  width: 3px;
  height: 8px;
  margin: 2px auto 0;
  display: block;
  background: #dedede;
  border-radius: 5px;
  -webkit-animation: 1.2s ease infinite wheel-up-down;
  animation: 1.2s ease infinite wheel-up-down;
}

.bgOverlay {
  background-color: #000000ba;
  opacity: 0.54;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0;
  position: absolute;
}

.VideoLayout {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

@-webkit-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    margin-top: 20px;
    opacity: 0;
  }
}

@keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    margin-top: 20px;
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .h1 {
    font-size: 1.4rem;
    margin-top: 8rem;
  }

  .SpaceReduce {
    font-size: 1.4rem;
    margin-top: 2rem;
  }

  .land-txt p {
    font-size:.9rem;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .land-txt {
    top: 6rem;
  }

  .h1 {
    font-size: 2rem;
  }

  .land-txt p {
    font-size: 1rem;
  }

  .mouseicon {
    width: 22px;
    height: 33px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  /* .h1{
      color: #FFFFFF;
      font-size: 2rem;
      font-weight: 700;
  } */
}

.BookAppBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.BookAppBtn button {
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #042b62;
  border-radius: 9px 9px 9px 9px;
  padding: 10px 15px;
  outline: none;
  border: none;
}

.BookAppBtn button:hover {
  color: #042b62;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}
@media only screen and (max-width: 600px) {
  .VideoLayout {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  .land-txt {
    top: 10rem;
    padding: .4rem;
  }

  .TextHello {
    font-size: 2rem;
    margin-top: 0rem;
  }
  .BookAppBtn button{
    font-size: 1rem;
  }
}
