.FpBenefits {
    padding: 5rem 0;
    background: #F1F5F9;
    width: 100%;
}

.FpBenefitssubtitle {
    text-align: center;
    padding: 1rem 0;
    font-size: 1.375rem;
    width: 80%;
    margin: auto;
    color: #4B5675;
}

.FpBenefitsBox {
    width: 80%;
    margin: auto;
}

.FpBenefits .FpBenefitstitle {
    font-size: 2.5rem;
    font-weight: 700;
    width: 50%;
    margin: auto;
}

.FpBenefitsTop {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.FpBenefitsBottom {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    gap: 2rem;
    width:70%;
    margin: auto;
}
.FpBenefitsCards{
    width: 70%;
    margin: auto;
}
.FpBenefitsCard {
    width: 50%;
    text-align: center;
    padding: 1.5rem;
    color: #12141D;
}

.FpBenefitsCardtitle {
    font-size: 1.1rem;
    font-weight: 600;
    padding-top: 1.6rem;
}

.FpBenefitsCardDec {
    font-size: 1rem;
    padding: .5rem 0;
    text-align: center;
   
}

/* Responsive design */
@media only screen and (max-width: 800px) {
    .FpBenefitsTop, .FpBenefitsBottom {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .FpBenefits .FpBenefitstitle{
        font-size: 1.4rem;
        width: 100%;
    }
    .FpBenefitssubtitle{
        width: 100%;
        font-size: 1rem;
    }
    .FpBenefitsCard{
        width: 100%;
    }
    .FpBenefitsCards{
        width: 100%;
    }
    .FpBenefitsTop{
        gap: 0;
        margin-bottom: 0;
    }
    .FpBenefitsBottom{
        gap: 0;
    }
}
