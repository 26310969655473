.ExpertFlow {
  margin-top: 2rem;
  padding-top: 2rem;
}
.ExpertFlow .BookTextlabel .BigText {
  color: #000d44;
  font-size: 1.3rem;
  font-weight: 700;
}
.ExpertFlow .BookTextlabel .SmallText {
  color: #042b62;
  font-size: 0.8rem;
  font-weight: 700;
}
.ExpertFlow input[type="search"] {
  background: url(https://static.fintoo.in/static/assets/userflow/img/icons/search_1.png)
    top 8px right 10px no-repeat;
  background-size: 19px;
  height: 35px;
  background-color: #fff;
  padding-left: 14px;
  border: 1px solid #e3e3e3;
  width: 40rem;
  margin-left: 1rem;
  border-radius: 22px;
  outline: none;
  font-size: 1rem;
}
.ExpertFlow input[type="search"]::placeholder {
  font-size: 1rem;
}
.ExpertFlow input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

@media screen and (max-width: 768px) {
  .ExpertFlow {
    text-align: center;
  }
  .searchbox{
    margin-top: 1rem;
    justify-content: center;
  }
  .Filter {
    border: 1px solid #e3e3e3;
    padding: 4px 10px 0px 10px;
    border-radius: 15px;
    height: 30px;
    width: 6rem;
    font-size: 0.8rem;
  }
  .BookTextlabel{
    margin: 0rem 1rem;
  }
  .ExpertFlow input[type="search"] {
    background: url(https://static.fintoo.in/static/assets/userflow/img/icons/search_1.png)
      top 8px right 10px no-repeat;
    background-size: 12px;
    height: 30px;
    padding-left: 14px;
    border: 1px solid #e3e3e3;
    width: 14rem;
    margin-left: 1rem;
    border-radius: 15px;
    outline: none;
  }
  .ExpertFlow .BookTextlabel .BigText {
    font-size: 1rem;
  }
  .ExpertFlow .BookTextlabel .SmallText {
    font-size: 0.9rem;
  }
}
