.card {
    width: 20%;
    padding: 10px;
}
.animated {
    animation-name: bounceInUp;
    animation-duration: 1s;
}
.card:nth-child(even) {
    margin-top: 70px;
}
.cardinner {
    padding: 10px;
    padding: 130px 20px 65px 20px;
}
.cardbox {
    padding: 0px 80px 0px 80px;
}
.cardcontainer {
    padding: 10px;
}
.cardheading {
    padding: 0px 0px 0px 0px;
    color: #042b62;
    font-size: 21px;
    font-weight: 400;
}
.cardparagraph {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
}
.card-fin {
    background-image: url('./images/Financial_Planning.svg');
    background-position: left 0;
    background-repeat: no-repeat;
    background-size: 130px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
}
.card-Retire{
    background-image: url('./images/Retirement_Planning.svg');
    background-position: left 0;
    background-repeat: no-repeat;
    background-size: 130px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
}
.card-invest{
    background-image: url('./images/Investment.svg');
    background-position: left 0;
    background-repeat: no-repeat;
    background-size: 130px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
}
.card-risk{
    background-image: url('./images/Risk_Management.svg');
    background-position: left 0;
    background-repeat: no-repeat;
    background-size: 130px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
}
.card-tax{
    background-image: url('./images/Tax_Planning.svg');
    background-position: left 0;
    background-repeat: no-repeat;
    background-size: 130px;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
}
@media (max-width: 767px) {
    .cardboxin {
        flex-direction: column;
    }
    .cardbox {
        padding: 0px;
    }
    .card {
        width: 100%;
    }
    .card:nth-child(even) {
        margin-top: 0px!important;
    }
    
}
@keyframes bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px);
    }
    100% {
        transform: translateY(0);
    }
}

