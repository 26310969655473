.nil-tooltip {
    position: relative;
    cursor: pointer;
}
.nil-tooltip-text {
    display: none;
    position: absolute;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 15px;
    padding: 1rem;
    font-size: .8rem;
    top: -1rem;
    left: 1.2rem;
}
.nil-tooltip:hover .nil-tooltip-text {
    display: block;
}