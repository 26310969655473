.accordian-item {
    height: 100%;
}

.accordian-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 1rem 2rem; */
    border-radius: 5px 5px 5px 5px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 400;
}

.accordian-content {
    background-color: rgba(255, 255, 255, 0);
    /* padding: 0 2rem; */
    /* margin: 0.5rem 0; */
    border-radius: .2em;
    height: 95px;
    overflow: hidden;
    transition: height ease 0.2s;
    color: #666666;
    font-size: 1.123rem;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.anchor-style {
    text-decoration: none;
    color: #042b62;
}
.accordian-item {
    border-top: 1px solid #D0D0D0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: .5rem;
    padding-right: .5rem;
}
.accordian-item-container .accordian-item:last-child {
    border-bottom: 1px solid #D0D0D0;
}


@media only screen and (max-width: 600px) {

    .accordian-header span{
        font-size: 1rem;
        width: 400px;
    }
    .accordian-header svg{
        width: 40px;
    }
}