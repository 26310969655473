.itemSide .sample {
  width: auto;
  flex: 1;
}
.itemSide .sample:first-child {
  width: none;
}
.itemSide .Sample span:first-child {
  font-weight: bold;
  font-size: 0.8em;
  text-align: left;
}
.itemSide .Value {
  color: gray;
}
.iposectionbox .ipotooltip {
  color: #000 !important;
  background: #fff !important;
  border: 1px solid #e3e3e3 !important;
  border-radius: 5px !important;
  padding: 10px !important;
}
.iposectionbox .ipotooltip::after {
  border: 1px solid #e3e3e3 !important;
  background-color: #fff !important;
}
.iposectionbox .ipotooltip p {
  margin: 0 !important;
  font-weight: 400;
}
.customSelect {
  justify-content: space-between;
  border: 1px solid #042b62;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  cursor: pointer;
}
.customSelect .label {
  color: gray;
}
.sortOptions {
  position: absolute;
  border: 1px solid #e3e3e3;
  margin: 1rem 0;
  margin-right: 1rem;
  background-color: #fff;
  z-index: 1000;
  border-radius: 5px;
}
.toggleswitch {
  display: flex;
  /* background-color: #ddd; */
  overflow: hidden;
}
.sortLabel {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.8rem 0.8rem;
  color: #666666;
}
.toggleswitch .option {
  flex: 1;
  padding: 4px;
  text-align: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.4rem 0.8rem;
  margin: 0 0.1rem;
  user-select: none;
  transition: all 0.3s ease;
}

.toggleswitch .active {
  background-color: #c9c9c9;
  color: #000;
}
.toggleswitch .Inactive {
  background-color: #f8f8f8;
}
.toggleswitch .hightolow {
  transform: translateX(0%);
}

.toggleswitch .lowtohigh {
  transform: translateX(100%);
}
/* For Filter Sidepanel */
.filter_text {
  font-size: 1.2rem;
  font-weight: 600;
}
.filterlabel {
  font-size: 0.8rem;
  color: gray;
  font-weight: 400;
}
.BondsSearchTxt {
  color: #e3e3e3;
}
.BondsSearchTxt::placeholder {
  font-weight: 600;
}
.bondName {
  webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 0.8rem;
  overflow: hidden;
  padding-right: 1rem;
  width: 700px;
}
.BondsBoxeslist .sectionName {
  color: #575757;
  font-size: 1.1rem;
  font-weight: bold;
}
.BondssectionDetails {
  display: flex;
  justify-content: space-between;
}
.Bondval {
  font-size: 1.1rem;
  font-weight: bold;
}
.Bonddesc .Bondlabel {
  color: #a2a2a2;
  font-size: 0.8rem;
  font-weight: bold;
}
.Bonddesc .Bondinfo {
  font-size: 0.9rem;
}
.hrline {
  color: #bebebe;
  margin: 2rem 0;
}
.BondskeysectionDetails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.BondComparebox {
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 2rem;
}
.BondComparebox .sectionName {
  padding: 1rem 2rem;
  padding-bottom: 0rem;
}
.CompareTable {
  /* padding: 0rem 1rem; */
  overflow: auto;
}
.CompareTable table {
  width: 100%;
}
.CompareTable table thead {
  background-color: #f8f5f5;
  /* border-radius: 20px; */
}
.CompareTable table thead th {
  color: #c0bdbd;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.8rem 1rem;
}
.CompareTable table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.CompareTable table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.CompareTable table tbody td {
  padding: 0.8rem 1rem;
  color: #000;
}
.bondTableData {
  font-size: 0.9rem;
  font-weight: 600;
  color: #000;
}
.CompareTable .bondTableName {
  font-size: 0.9rem;
  font-weight: 600;
  color: #000;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.bondRightSection {
  position: sticky;
  top: 110px;
}
.bondRightSection .bondAskfintoo {
  margin: 0rem 1rem;
  border: 2px solid #fff;
  text-align: center;
  border-radius: 15px;
  margin-top: 0.7rem;
  background: linear-gradient(to top right, #fbfbfb 16%, #d1cece 110%);
}
.bondAskfintoo p {
  margin: 2rem 4rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #909090;
}
.bondAskfintoo button {
  width: 100%;
  background-color: #042b62;
  padding: 0.7rem 0px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 800;
  border: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  outline: none;
}
.BondCalculator {
  margin: 2rem 1rem;
  border: 2px solid #fff;
  text-align: center;
  border-radius: 15px;
  /* margin-top: 0.7rem; */
  background: #f4f4f4;
}
.bondheader {
  color: #9b9b9b;
  padding: 1.2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid #dbdbdb;
}
.countercontainer {
  margin: 2rem 8.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #8ccde9;
  padding: 1px;
  border: 2px solid #ccc;
  border-radius: 40px;
  outline: none;
  border: none;
}

.countercontainer .button {
  width: 25px;
  height: 25px;
  background-color: #ffff;
  color: #000;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  color: #042b62;
  font-weight: bold;
}
.countercontainer .count {
  font-size: 1rem;
}
.calDetails {
  display: flex;
  justify-content: center;
  text-align: left;
}
.calDetails .Vrline {
  border-right: 1px solid #d3d1d1;
  margin: 0rem 1rem;
  /* margin-top: .6rem; */
  height: 3rem;
}
.calDetails .label {
  font-size: 0.8rem;
  color: #c0bdbd;
  font-weight: 600;
}
.calDetails .Amount {
  font-size: 1rem;
  font-weight: bold;
  padding-top: 0.1rem;
}
.bondvaliddate {
  font-size: 0.5rem;
}
.investtitle {
  color: #c0bdbd;
  font-size: 1.2rem;
  font-weight: 500;
}
.BondCalculator .investamount {
  font-weight: bold;
  font-size: 1.3rem;
}
.maturityReturn {
  /* margin: 0rem 1rem; */
  border: 1px solid #fff;
  text-align: center;
  border-radius: 15px;
  background: linear-gradient(to top right, #fbfbfb 7%, #bdbdbd 136%);
  padding-bottom: 1rem;
}
.maturityReturn .bondheader {
  border: 0;
  padding-bottom: 0;
}
.DisclaimerSection {
  display: grid;
  place-items: center;
  text-align: center;
  margin-bottom: 3rem;
}
.DisclaimerSection .DisclaimHead {
  font-size: 0.8rem;
  color: #909090;
  font-weight: bold;
}
.Disclaimtxt {
  font-size: 0.9rem;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .itemSide .Sample {
    flex: auto;
  }
}
@media only screen and (max-width: 768px){
  .BondssectionDetails {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .Bondval{
    font-size: .9rem;
    font-weight: 800;
  }
  .Bonddesc .Bondinfo{
    font-size: 0.8rem;
    padding-top: 4px;
  }
  .BondskeysectionDetails{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .CompareTable table thead{
    overflow: auto;
    white-space: nowrap;
  }
  .CompareTable table tbody td{
    white-space: nowrap;
  }
  .countercontainer{
    margin: 2rem 6.6rem;
  }
  .mobileBondOptions{
    position: fixed;
    bottom: 0px;
    width: 100vw;
    background: #fff;
    padding: 10px;
    left: 0;
    display: flex;
    justify-content: center;
  }
  .mobileBondOptions button{
    background-color: #042b62;
    border: 0;
    border-radius: 30px;
    color: #fff;
    padding: .5rem 2rem;
    margin: 0 1rem;
    font-weight: 600;
  }
  .BondCalculator{
    margin: 0rem 0rem;
    margin-top: 3rem;
    border: 1px solid #fff;
  }
  .maturityReturn{
    border: 1px solid #fff;
  }
  .itemSide{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0rem;
    grid-row-gap: 0px;
  }
}
