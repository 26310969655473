.heading-separetor-container{
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}
.border-divider {
    height: 4px;
    width: 90px;
    background: #042b62;
    border-radius: 2px;
    position: relative;
}

.border-divider::before {
    content: "";
    position: absolute;
    color: #042b62;
    left: -27px;
    top: 0;
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #042b62;
    -webkit-box-shadow: 9px 0 0 0 currentColor, 18px 0 0 0 currentColor;
    box-shadow: 9px 0 0 0 currentColor, 18px 0 0 0 currentColor;
}

/*  */
.heading-separetor-container2{
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}
.border-divider2 {
    height: 4px;
    width: 90px;
    background: #ffff;
    border-radius: 2px;
    position: relative;
}

.border-divider2::before {
    content: "";
    position: absolute;
    color: #ffff;
    left: -27px;
    top: 0;
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #ffff;
    -webkit-box-shadow: 9px 0 0 0 currentColor, 18px 0 0 0 currentColor;
    box-shadow: 9px 0 0 0 currentColor, 18px 0 0 0 currentColor;
}