.login_header {
  position: absolute;
  top: 0;
  width: 59%;
  z-index: 1;
  padding: 15px;
}
.login_header .back_arrow {
  top: 7rem;
}
.back_arrow {
  display: block;
  position: absolute;
  cursor: pointer;
  top: 30px;
  left: 30px;
  background-color: rgba(0, 82, 99, 0.14);
  padding: 14px;
  border-radius: 12px;
  z-index: 1;
}
.back_arrow img {
  display: block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.login_block {
  margin-top: 0rem;
}

.page_header {
  font-size: 2.4rem;
  font-weight: 550;
  line-height: 1.5;
  margin-top: 0rem;
}

.page_subTxt {
  color: #383838;
  font-size: 1.1rem;
}
.PlanSubscription {
  margin-top: 7rem;
  height: 100%;
}
.PlanType {
  /* text-align: center; */
  background-color: #042b62;
  margin: auto;
  margin-top: 4rem;
  border-radius: 10px;
}
.Hrborder {
  border-right: 1px solid #e7e7e7;
  height: 2rem;
  padding: 0rem 2rem;
  /* width: 3px; */
}
.login_illustration {
  background: url(../images/02_subscription.png) no-repeat;
  background-size: 120%;
  background-position: right 10%;

  /* margin-right: 2rem; */
  height: 100vh;
}
.radio_subs_radio {
  background-color: #042b62;
  margin: 8px 0px 8px 0 !important;
  color: #fff;
  font-weight: 700;
  border-radius: 10px;
}
.radio_subs_radio label {
  padding: 12px;
}
.subs_right_amt {
  text-align: right;
  color: #fff;
  font-weight: 700;
  padding: 12px;
  border-radius: 10px;
}
.applyoffer_div {
  padding: 5px 5px 5px 10px;
  border: 1px solid #8bc53f;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
  margin: 0px;
  max-width: 200px;
  outline: none;
  
  display: flex;
}
.apply_coupon_code {
  float: left;
  color: #8bc53f;
  border: 2px solid transparent;
  background-color: #fff;
  padding: 0px;
  margin-left: 5px;
  margin-top: -1px;
  font-size: 1rem;
  font-weight: 700;
  padding-top: 3px;
}

.applyoffer_div img {
  float: left;
  width: 19px;
  padding-top: 4px;
  vertical-align: middle;
}
.nextArrow {
  float: right;
  font-size: 1.2rem;
  padding-left: 1rem;
  vertical-align: top;
}
.pay_block {
  margin: 2rem 0rem;
}
.pay_block .text {
  font-size: 13px !important;
  font-weight: 500;
  padding-top: 10px;
  text-align: right;
}
.net_payable {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  padding-top: 7px;
  margin-top: 8px;
}
.pay_block .price {
  font-size: 1.2rem !important;
  text-align: right;
  padding-top: 7px;
}
.checkbox_container {
  position: relative;
}
.checkbox_container .custom_checkbox {
  display: block;
  position: absolute;
  opacity: 0;
  left: 4px;
  top: 5px;
  cursor: pointer;
  z-index: 1;
}
.custom_checkbox + label:before {
  content: "";
  margin-right: 10px;
  background: #042b62;
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  height: 18px;
  background: 0 0;
  border: 1px solid #042b62;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 2px;
}
.checkbox_label {
  font-size: 1rem;
}
.custom_checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  padding-left: 30px;
  display: block;
  line-height: 20px;
  text-align: left;
}
.checkbox_label a {
  display: inline-block;
  color: #042b62;
  padding-left: 4px;
  text-decoration: none;
  border-bottom: 1px solid #042b62;
}
.custom_checkbox:checked + label:before {
  background: #042b62;
}
.custom_checkbox:checked + label:after {
  content: "";
  border: 2px solid #fff;
  border-top: none;
  border-right: none;

  width: 12px;
  height: 6px;
  position: absolute;
  top: 6px;
  left: 3px;
  transform: rotate(-45deg);
}
.default_btn {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  background: #042b62;
  color: #fff;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;
  padding: 10px 80px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  margin: 3rem 0;
  transition: 0.2s all ease-out;
}
.Billingpopup {
  border-radius: 20px;
  border: none;
  outline: none;
}
.BillingHeader {
  background-color: #042b62;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 10px;
  color: #fff;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}
.Billingpopup table {
  width: 100%;
  border-collapse: collapse !important;
}
.Billingpopup table td {
  padding: 0.6rem 2rem;
}
.two_col_list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.bill_details_list li div {
  padding: 12px 30px;
}
.color_blue {
  color: #042b62;
}
.bill_details_list li {
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: 500;
}
.bill_details_list li .Bottom {
  border-bottom: 1px solid #e7e7e7;
}
.bill_details_list li div:first-child {
  max-width: 60%;
  flex: 0 0 60%;
  text-align: left;
  border-right: 1px solid #afdce1;
}
.bill_details_list li.fill_row {
  background: #efefef;
  /* border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important; */
}
.bill_details_list li div:last-child {
  max-width: 40%;
  flex: 0 0 40%;
  text-align: left;
}
.BillingHeader span {
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  top: -4px;
  cursor: pointer;
}
.coupon_list{
    padding: 10px 40px;
}
.coupon_name {
    display: flex;
    justify-content: center;
    align-items: center;

}
.coupon_name .coupon {
    border: 1px dashed #042b62;
    border-radius: 20px;
    padding: 8px 30px;
    font-weight: 500;
    color: #042b62;
 
}
.coupon_list .default_btn {
    border-radius: 30px;
    position: relative;
    display: inline-block;
    padding: 8px 50px;
    border: 1px solid transparent;
}

.discount {
  color: #042b62;
}