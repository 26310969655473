.KeyElements {
    padding: 4rem 0;
    background: #fff;
    width: 100%;
}

.KeyElementsBox {
    width: 70%;
    margin: auto;
}

.KeyElements .KeyElementstitle {
    font-size: 2.5rem;
    font-weight: 700;
    width: 100%;
    margin: auto;
    color: #252F4A;
}
 .KeyElementsCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    width: 70%;
    margin: auto;
}

.KeyElementsCard {
    border-radius: .5rem;
    border: 1px solid #E3E3E3;
    background: #FAFAFA;
    padding: 1rem;
    margin-top: 2rem;
}

.KeyElementsCardtitle {
    font-size: 1.5rem;
    font-weight: 600;
    padding-top: 1.6rem;
}

.KeyElementsCardDec {
    font-size: 1rem;
    padding: .5rem 0;
}
.KeyElementsCard:hover{
    background-color: #004FBF;
    color: #fff;
    transition: .5s;
}

/* Responsive  */

@media only screen and (max-width: 800px) {
    .KeyElements {
        padding: 4rem 2rem;
    }
    .KeyElementsBox{
        width: 100%;
    }
    .KeyElements .KeyElementstitle  {
        font-size: 1.4rem;
        width: 100%;
    }

    .FPPlannerinfo {
        width: 100%;
        font-size: 1rem;
    }
    .KeyElementsCards{
        width: 100%;
        grid-template-columns : repeat(1, 1fr)
    }
    .KeyElementsCardtitle {
        font-size: 1rem;
    }
    .KeyElementsCardDec{
        font-size: .8rem;
    }
}