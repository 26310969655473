.container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    width: fit-content;
    margin: 0 auto;
    border-radius: 50px;
    background-color: white;
}

.tabGlider {
    /* width: fit-content; */
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #042b62;
    z-index: -1;
    border-radius: 50px;
    transition: left .4s;
    /* transform: ; */
}

.tabElem {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: #042b62;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    padding: 1rem;
    cursor: pointer;
}

.tabElemActive {
    color: white !important;
}

@media (max-width:550px)  {
    .container{
        margin: 1rem auto;
    }
    .tabElem{
        font-size: 0.9rem;
        padding: 0.5rem;
    }
}