.downloadSpinner {
    width: 20px;
    height: 20px;
    border: 2px solid #042b62;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
.buttonStyle12 {
    background-color: #042b62;
    padding: .6rem 1.6rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    border: 0;
    outline: 0;
    border-radius: 25px;
    margin-top: 2rem;
}

.fetchloanpopup .modal_Heading {
    font-size: 1.2rem;
}

.fetchloanpopup .CloseBtnpopup {
    position: absolute;
    right: 10px;
}

.fetchloanpopup .CloseBtnpopup img {
    width: 30px;
}

.fetchloanmodalBody {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.fetchloanmodalBody .LeftSection {
    /* width: 60%; */
    background-color: #F9F9F9;
    padding: 3rem;
    border-radius: 15px;
}

.fetchloanmodalBody .RightSection {
    /* width: 40%; */
    background-color: #fff;
    padding: 4rem 2rem;
    border-radius: 15px;
}

.fetchloanmodalBody .title {
    color: #000000;
    font-size: 1rem;
    font-weight: 600;
}

.fetchloanmodalBody .inputField {
    padding: 10px;
    border: 1px solid #e3e2e2;
    width: 100%;
    border-radius: 10px;
}

.fetchloanmodalBody .RightSection img {
    width: 440px;
}

.fetchloanmodalBody .fetchbtn {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.fetchloanmodalBody .fetchbtn button {
    border: 0;
    outline: 0;
    padding: .7rem 1.7rem;
    background: #042b62;
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
}

.fetchloanmodalBody .Stepper {
    margin: 1.6rem 0;
}

.fetchloanmodalBody .boxinactive .progressbox {
    background: #e8f8ff;
    color: #042b62;
    border: 1px solid #042b62;
}

.fetchloanmodalBody .boxactive .progressbox::before {
    border: 1px dashed #042b62;
}

.fetchloanmodalBody .boxinactive .progressbox::before {
    border: 1px dashed #dbdfe9;
}

.fetchloanmodalBody .verifiedstep .progressbox {
    background: #ff4a4a;
    color: #fff;
}

.fetchloanmodalBody .verifiedstep .rightSection .stepTitle {
    color: #ff4a4a;
}

.fetchloanmodalBody .Stepperlist {
    display: flex;
    align-items: center;
}

.fetchloanmodalBody .progressbox {
    border-radius: 0.60031rem;
    background: #042b62;
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    margin-top: 2rem;
}

.fetchloanmodalBody .progressbox:before {
    content: "";
    border: 1px dashed #042b62;
    position: absolute;
    margin-top: -6rem;
    height: 44px;
}

.fetchloanmodalBody .Stepper:first-child .progressbox:before {
    display: none;
}

.fetchloanmodalBody .Stepper:last-child .progressbox:before {
    display: block;
}

.fetchloanmodalBody .Stepper .rightSection {
    margin-left: 1.3rem;
    margin-top: 2rem;
}

.fetchloanmodalBody .stepTitle {
    font-size: 1rem;
    font-weight: bold;
    color: #1E1E1E;
    text-transform: capitalize;
}

.fetchloanmodalBody .stepsubTitle {
    color: #99a1b7;
}

.fetchloanmodalBody .boxinactive .progressbox {
    background: #e8f8ff;
    color: #042b62;
    border: 1px solid #042b62;
}

.fetchloanmodalBody .boxactive .progressbox::before {
    border: 1px dashed #042b62;
}

.fetchloanmodalBody .boxinactive .progressbox::before {
    border: 1px dashed #dbdfe9;
}

.fetchloanmodalBody .boxinactive .stepTitle {
    color: rgba(30, 30, 30, 0.50);
}

.fetchloanmodalBody .notfoundtext {
    font-size: 1.3rem;
    font-weight: 600;
    padding-top: 1rem;
}

.fetchloanmodalBody .notfoundsubtext {
    color: #1E1E1E;
    font-size: 1rem;
    padding-top: 1rem;
}

.fetchloanmodalBody .button_1 {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

.fetchloanmodalBody .button_1 button {
    outline: 0;
    border: 0;
    background-color: #042b62;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1.5rem;
    margin: 0rem 1rem;
    border-radius: 20px;
    font-weight: bold;
}

.fetchloanmodalBody .button_1 button:last-child {
    background-color: transparent;
    border: 1px solid #042b62;
    color: #042b62;
}

.fetchloanmodalBody .button_1 button:last-child:hover {
    background-color: #042b62;
    color: #fff;
}

.loanDatepicker {
    background-color: #fff;
    padding: 7px;
    border: 1px solid #e3e2e2;
    width: 100%;
    border-radius: 10px;
}

/* For Mobile Responsive */
@media only screen and (max-width: 600px) {
    .fetchloanpopup .modal_Heading{
        font-size: 1rem;
    }
    .fetchloanmodalBody {
        flex-direction: column;
        flex-direction: column-reverse;
    }
    .fetchloanmodalBody .notfoundsubtext{
        font-size: .9rem;
        padding: 1rem;
        text-align: center;
    }
    .fetchloanmodalBody .RightSection{
        padding:3rem 2rem;
    }
    .fetchloanmodalBody .RightSection img {
        width: 300px;
    }
    .fetchloanmodalBody .LeftSection{
        margin: 1rem;
        padding: 1rem;
    }
    .fetchloanmodalBody .stepTitle{
        font-size: .9rem;
    }
    .fetchloanmodalBody .button_1 button{
        font-size: .8rem;
    }
   
}