.popupBackdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #0000006e;
    z-index: 99
}

.infoIcon {
    position: absolute;
    right: 0;
    font-size: 1.7rem;
    color: #b3b3b3;
    cursor: pointer;
    /* z-index: 100 */
}

.popupElem {
    position: absolute;
    right: 17%;
    background: white;
    border-radius: 15px;
    width: 30%;
    padding: 1rem;
    z-index: 100
}

@media only screen and (max-width: 900px) {
    .popupElem {
        right: 7%;
    }
}

@media only screen and (max-width: 500px) {
    .recurringFormContainer {
        display: unset;
    }

    .popupElem {
        width: 90%;
    }
}