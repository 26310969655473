.accordian-item {
    height: 100%;
}

.accordian-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-radius: 5px 5px 5px 5px;
    box-shadow: -1px 7px 15px 0px rgb(0 0 0 / 7%);
    cursor: pointer;
    font-size: 1.123rem;
    font-weight: 600;
}

.accordian-content {
    background-color: rgba(255, 255, 255, 0);
    padding: 0 2rem;
    margin: 0.5rem 0;
    border-radius: .2em;
    height: 95px;
    overflow: hidden;
    transition: height ease 0.2s;
    color: #666666;
    font-size: 1.123rem;
    font-weight: 600;
    line-height: 24px;
}
.anchor-style {
    text-decoration: none;
    color: #042b62;
}