.MainCOntainer {
    padding: 5rem;
    background-color: #00000018;
}

.CalcListContainer {
    padding: 0 5rem 2rem 5rem;
    background-color: white;
    border-radius: 15px;
    margin-top: -15px;
}

.ContainerTop {
    height: 35px;
    border-radius: 15px 15px 0 0;
    background-color: #042b62;
}

.search-filter-container {
    padding-top: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading-container {
    display: flex;
    align-items: center;
    width: 20%;
    gap: 1.5rem;
}

.heading-text {
    font-size: 2rem;
    font-weight: bolder;
}

.search-input {
    width: 50%;
    padding: 0.5rem 1rem;
    border-radius: 35px;
    border-color: rgba(104, 104, 104, 0.601);
    color: rgb(35, 35, 35);
    border-style: solid;
}

.search-input:focus {
    border-color: rgba(104, 104, 104, 0.807);
    outline: none;
}

.HrSeperator {
    width: 100%;
    height: 1px;
    background-color: #042b62;
    margin: 2rem 0;
}

.filter-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem;
}

.radio-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
}

.radio-container input[type="radio"] {
    -ms-transform: scale(1.2);
    /* IE 9 */
    -webkit-transform: scale(1.2);
    /* Chrome, Safari, Opera */
    transform: scale(1.2);
}

.calcdisplay-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    gap: 2rem;
    margin-top: 2rem;
}

.calcdisplay-card {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(80, 80, 80, 0.024);
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.133);
    padding: 2rem 1rem;
}

.calcdisplay-card-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.calcdisplay-card-txt {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.calcdisplay-card-calc-btn {
    border: none;
    background-color: #042b62;
    color: white;
    padding: 0.2rem 0.7rem;
    border-radius: 20px;
    font-size: 0.9rem;
    width: fit-content;
    text-decoration: none;
    cursor: pointer;
}

.calcdisplay-card-calc-btn:hover {
    color: white;
}

.calcdisplay-card-calc-img {
    position: absolute;
    height: 60px;
    right: 0;
    bottom: 0;
    margin-right: 1rem;
}

.no-result-container {
    height: 60px;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    /* font-weight: bold; */
}

@media only screen and (max-width: 850px) {
    .calcdisplay-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 450px) {
    .calcdisplay-container {
        grid-template-columns: 1fr;
    }

    .CalcListContainer {
        padding: 0 1rem 2rem 1rem;
    }

    .MainCOntainer {
        padding: 2rem 1rem;
    }

    .heading-container {
        width: 100%;
        /* flex-direction: column; */
    }

    .search-input {
        width: 100%;
    }

    .search-filter-container {
        padding: 2rem 0 0 0;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }

    .heading-container {
        margin-bottom: 1rem;
    }

    .filter-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}