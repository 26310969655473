
.ExpertReviewSection{
   width: 100%;
    background-color: #0E0D0E;
}
.ExpertReviewSection  img{
    width: 200px;
    height: 294px;
}
.ExpertReview {
    width: 74%;
    margin: auto;
    gap: 2rem;
    padding: 3.4rem 0;
    color: #fff;

}
.ExpertReviewImg img{
    margin-top: 1rem;
    border-radius: 10px;
    object-fit: cover;
    width: 200px;
    height: 270px;
}
.ExpertReviewtitle {
    font-size: 2.5rem;
    font-weight: 700;
}
.ExpertReviewsubtitle{
    font-size: 1.1rem;
    font-weight: 400;
    width: 40%;
    margin: auto;
    text-align: center;
}
.ExpertReview .expertName{
    color: #FFF;
    font-size: 1rem;
}
.ExpertReview .expertDesign{
    color: #999;
    font-size: 1rem;
}
.ExpertReview .expertInfo{
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    padding: 1.4rem 0;
}

/* Responsive design */
@media only screen and (max-width: 800px) {
    .ExpertReview{
        width: 100%;
        place-items: center;
        text-align: center;
        padding: 3.4rem 2rem;
    }
    .ExpertReview .expertInfo{
        font-size: 1.1rem;
    }
}