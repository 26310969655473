.padding-class {
    padding: 0 12rem;
}

.widget-content-text {
    color: #666666;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 24px;
}

.widget-header-text {
    padding: 0px 0px 0px 0px;
    color: #000000;
    font-size: 1.5rem;
    font-weight: 600;
}

.section-content-text {
    color: #86898b;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 24px;
}
/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
    .padding-class {
        padding: 0 2rem;
    }
  }
@media only screen and (max-width: 600px) {
    .padding-class {
        padding: 0 1rem;
    }
    .widget-header-text {
        color: #000000;
        font-size: 1.2rem;
    }
.widget-content-text {
        font-size: 1rem;
    }
  }