.investBox {
  width: 80%;
  margin: auto;
  /* display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(4, 1fr);
grid-column-gap: 2rem;
grid-row-gap: 0px; */
  /* display: flex; */
}

.mytable {
  width: 100%;
}
.mytable td {
  width: 50%!important;
  vertical-align: top;
}
.investType {
  width: 100%;
  transition: all .4s ease;
  color: #000000;
  background-color: #ffffff;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #f0f5fd;
  border-radius: 5px 5px 5px 5px;
  padding: 1rem 2rem;
  /* margin-bottom: 1rem; */
  box-shadow: -1.216px 6.894px 15px 0 rgb(37 117 252 / 20%);
}
.topBox{
  justify-content: space-between;
  transition: all .4s ease;
}
.BottomBox{
  padding: 2rem;
  max-width: 90%;
  background-color: #042b62;
  color: #ffff;
  width: calc(100% - 0px);
  border-radius: 0 0 5px 5px;
  position: relative;
  margin-left: auto;
  font-size: 1.2rem;
  margin-right: auto;
  transition: all .4s ease;
  /* margin:0rem 2rem; */
}
.investTxt{
    color: #000000;
    font-size: 1.1rem;
    font-weight: 600;
}
.Icons svg{
    font-size: 1.5rem;
    font-weight: 600;
    color: #042b62;
}
.accordian-item {
  height: 100%;
}

.accordian-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 5px 5px 5px 5px;
  box-shadow: -1px 7px 15px 0px rgb(0 0 0 / 7%);
  cursor: pointer;
  font-size: 1.123rem;
  font-weight: 600;
}

.accordian-content {
  background-color: rgba(255, 255, 255, 0);
  border-radius: .2em;
  /* height: 0;
  overflow: hidden; */
  transition: height ease 0.2s;
  color: #666666;
  font-size: 1.123rem;
  font-weight: 600;
  line-height: 24px;
  /* width: calc(100% - 40px); */
  /* position: absolute; */
}
.anchor-style {
  text-decoration: none;
  color: #042b62;
}
@media screen and (max-width: 768px) {
  .investBox{
    width: 100%;
  }
  .topBox{
    display: flex;
  }
  .investTxt{
  font-size: 1rem;
  }
}