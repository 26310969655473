.AdvisorycardSection {
  background-color: rgb(230, 234, 239);
  padding: 4rem;
}
.SectionSubText {
  width: 80%;
  margin: auto;
  color: #86898b;
  font-size: 1.2rem;
  font-weight: 450;
}

.AdvisorycardSection-section-container {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  width: 80%;
  margin: auto;
  position: relative;
  z-index: 10;
  text-align: center;
}
.Advisorycard {
  background-color: #ffffff;
  padding: 1rem;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #f5f5f5;
  border-radius: 5px 5px 5px 5px;
  width: 100%;
  height: 20rem;
}
.CardPara {
  color: #656565;
  font-size: 1.2rem;
  padding: 0rem;
}
.CardIcon {
  font-size: 3rem;
  width: 16%;
  margin: auto;
  background-color: #f5f5f5;
  border-radius: 50%;
  padding: .5rem;
}
.CardTitle {
  margin-top: 1rem;
  padding: 0;
  color: #000000;
  font-size: 1.2rem;
  font-weight: 600;
}
.AdvisorycardBtn {
  margin-bottom: 1rem;
  padding: 0.6rem 2rem;
  border-radius: 5px;
  background: #fff;
  border: 2px solid #042b62;
  color: #000000;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}
.Advisorycard:hover {
  /* background-color: #042b62; */
  /* color: #fff; */
  border: 1px solid #042b62;
  transition: all 0.4s ease;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
      transform: translatey(0px);
  }

  50% {
      transform: translatey(-10px);
  }

  100% {
      transform: translatey(0px);
  }
}
@media only screen and (max-width: 768px) {
  .AdvisorycardSection {
    background-color: rgb(230, 234, 239);
    padding: 1rem;
    margin-bottom: 2rem;
  }
  .AdvisorycardSection-section-container {
    display: grid;
    margin: 2rem 0rem 2rem 0rem;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    width:100%;
  }
  .Advisorycard {
    background-color: #ffffff;
    padding: 1rem;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
    width: 100%;
    /* height: 100%; */
    margin-top: 1rem;
  }
  .CardIcon {
    font-size: 4rem;
    width: 30%;
  }
  .CardTitle {
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: 1.3rem;
    font-weight: 500;
  }
  .CardPara {
    color: #656565;
    font-size: 1rem;
    padding: 1rem;
  }
}
@media only screen and (max-width : 850px){
  .AdvisorycardSection-section-container{
    width: 100%;
  }
}