.WhoisfpPlanner {
    padding: 4rem 0;
    background: #F1F5F9;
    width: 100%;
}

.WhoisfpPlannertitle {
    font-size: 2.5rem;
    font-weight: 700;
    width: 70%;
    margin: auto;
    color: #252F4A;
}

.WhoisfpPlannersubtitle {
    text-align: center;
    padding: 1rem 0;
    font-size: 1.375rem;
    width: 65%;
    color: #4B5675;
    margin: auto;
}

.FPReportBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 65%;
    margin-top: 2rem;
}

.FPReport {
    margin-bottom: 3rem;
    display: grid;
    gap: 4rem;
}

.FPReportImg img {
    width: 100%;
}

.FPReportBoxContent {
    width: 37%;

}

.FPReportBoxContent .FPReportBoxContentTitle {
    font-size: 1.3125rem;
    font-weight: 500;
    color: #12141D;
}

.FPReportBoxContentText {
    color: #12141D;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: left;
}

/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */

@media only screen and (max-width: 800px) {
    .FPReport{
        gap: 2rem;
    }
    .FPReportBox {
        display: grid;
        margin: auto;
        width: 100%;
        margin-top: 1rem;
    }

    .FPReport .FPReportBox:nth-child(even) {
        display: inline-flex;
        flex-direction: column-reverse;
        margin-top: 1rem;
    }

    .FPReportBoxContent {
        width: 100%;
        margin-top: 1rem;
    }

    .FPReportBoxContentText {
        font-size: .9rem;
    }
.FPReportImg{
    text-align: center;
}
    .FPReportImg img {
        width: 300px
    }

    .FPReportBoxContent .FPReportBoxContentTitle {
        font-size: 1.2rem;
    }

    .WhoisfpPlanner {
        padding: 4rem 2rem;
    }

    .WhoisfpPlannertitle {
        font-size: 1.4rem;
        width: 100%;
    }
    .WhoisfpPlannersubtitle{
        font-size: 1rem;
        width: 100%;
    }
}