.mpc-1 {
    padding-left: 2.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #eee;
    line-height: 1rem;
    padding-right: 1rem;
    border: 2px solid #eee;
    width: 20rem;
    font-weight: 600;
}
.mpc-2 {
    line-height: 1rem;
    padding-top: .9rem;
    padding-bottom: .9rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border: 2px solid #eee;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
    display: none;
}
.mpc-2 p {
    padding-right: 1rem;
    padding-left: 6rem;
}
.my-profile-complete {
    position: relative;
    margin-left: 2rem;
}
.my-profile-complete:before {
    content: '';
    width: 4rem;
    height: 4rem;
    background-image: url('./images/kyc.png');
    background-size: cover;
    position: absolute;
    left: -2rem;
}

@media (min-width: 768px) {
    .mpc-2 {
        display: flex;
    }
}