.SetApartSectionSection {
    background-color: #E8F6FC;
}

.GlobalText2 {
    padding: 2rem;
    color: black;
    font-size: 2rem;
    font-weight: bold;
}

.flipCardContainer {
    /* display: grid;
    grid-template-columns: repeat(5, 1fr); */

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

/* Style for the flip card container */
.flipCard {
    width: 240px;
    height: 210px;
    perspective: 1000px;
    /* Required for the 3D flip effect */
}

.flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    cursor: pointer;
}

/* Flip effect on hover */
.flipCard:hover .flipCardInner {
    transform: rotateY(180deg);
}

/* Style for the front of the card */
.flipCardFront,
.flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flipCardFront {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #042b62;
    color: white;
    text-align: center;
    padding: 1rem;
    border-radius: 20px;
    font-size: 1.2rem;
    font-weight: 900;
}

.flipCardFrontIcon {
    width: fit-content;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 3px solid white;
    padding: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.3rem;
}

/* Style for the back of the card */
.flipCardBack {
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: #042b62;
    color: white;
    text-align: center;
    border-radius: 20px;
    font-size: 1.1rem;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .flipCardContainer {
        flex-wrap: wrap;
        gap: 2rem;
    }
.GlobalText2{
    font-size: 1.5rem;
}
    .flipCard {
        width: 80%;
        margin-bottom: 1rem;
    }
}