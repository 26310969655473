.PlanSection{
    padding: 4rem 0;
    background: #fff;
}
.PlanSectiontitle{
    font-size: 2.5rem;
    font-weight: 600;
    color: #252F4A;
   
}
.PlanSectionsubtitle{
    text-align: center;
    padding: 1rem 0;
    font-size: 1.375rem;
    width: 60%;
    margin: auto;
    color: #4B5675;
}
.PlanSection .plantitle{
    font-size: 1.3125rem;
    font-weight: 700;
    padding-bottom: .5rem;
}
.PlanSection .plansubtitle{
    font-size: 1.0625rem;
    color: #161C2D;
    opacity: 0.7;
    width: 62%;
}
.PlanSection .Plantype{
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 2rem;
    width: 60%;
    place-content: center;
    margin: auto 25%;
}

/* Responsive  */

@media only screen and (max-width: 800px) {
    .PlanSection{
        padding: 4rem 2rem;
    }
    .PlanSectiontitle{
        font-size: 1.4rem;
    }
    .PlanSectionsubtitle{
        width: 100%;
        font-size: 1rem;
    }
    .PlanSection .Plantype{
        grid-template-columns:repeat(1, 1fr);
        width: 100%;
        margin: auto;
    }
    .PlanSection .plantitle{
        font-size: 1rem;
    }
    .PlanSection .plansubtitle{
        font-size: .8rem;
        width: 100%;
    }
}