.FinancialReport {
  background-color: rgb(230, 234, 239);
  padding: 1rem;
  display: grid;
 place-items: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .FinancialReport {
    background-color: rgb(230, 234, 239);
    padding: 1rem;
    display: block;
  }
}
