
.MFData {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.blogDot{
    height: 6px;
    width: 6px;
    margin: 0 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
.button {
    background-color: #042b62;
    color: #fff;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    width: 8rem;
    font-weight: 700;
    border-radius: 20px;
    border: 1px solid #042b62;
    font-size: .9rem;
}
.Datatext p {
    font-size: 1em;
    font-weight: 600;
}
.tabBx {
    width: 12rem;
}
.tabBx span {
    border-bottom: 1px solid #888;
    padding: 10px;
}
.insideTabBoxd {
    border-bottom: 2px solid #eee;
}
.tabText {
    padding-bottom: 10px;
    border-bottom: 3px solid transparent;
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 600;
    color: darkgray;
}
.tabBx.active .tabText {
    border-bottom: 3px solid #042b62;
    color: #000;
}
.insidePage {
    padding-left: 2rem;
    padding-right: 2rem;
}
.Newsbox {
    border: 1px solid #f1f3f4;
    /* background-color: #f1f3f4; */
    /* padding: 1.7rem 1rem; */
    border-radius: 12px;
    outline: none;
    /* margin-right: 1rem;
    margin-left: 1rem; */
}
.img_8 {
    width: 100%;
    height: 200px;
    /* object-fit: cover; */
    /* border-radius: 12px; */
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.blogTitle {
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 1rem;
    font-size: 1.1rem;
}
.NewsText {
    font-size: .8rem;
    color: #6a6a6a;
    padding-right: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.AddImg {
    background-color: #ececec;
    border-radius: 10px;
    width: 50px;
    height: 50px;
}
.AddImg img {
    width: 25px;
    height: 25px;
    margin: auto;
    margin-top: 0.7em;
    display: grid;
    align-items: center;
    place-items: center;
}
.newsData {
    margin-left: 2rem;
    padding-top: 0.3rem;
}
.NewsBottomLine {
    color: #c6c6c6;
}
.Newstitle {
    color: #000;
    font-weight: 600;
}
.linkbox {
    background: #f1f3f4;
    border-radius: 12px;
}
