.heroSection {
    background: url('./Layer.webp') left center / auto 100% no-repeat,
        url('./Hero-image.webp') center center / cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 10rem 0;
}

.herotitle {
    font-size: 3.4375rem;
    max-width: 900px;
    font-weight: bold;
    color: #fff;
    line-height: 3.8rem;
    padding-top: 4rem;
}

.subtitle {
    color: #fff;
    font-size: 1rem;
    padding: 1rem 0;
    max-width: 700px;
    text-align: left;
    opacity: 0.6;
}

.formview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    width: 70%;
    margin-top: 3rem;
}

.formview input {
    border-radius: 8px;
    border: none;
    font-size: 1rem;
    background: #fff;
    padding: .8rem;
    width: 100%;
}

.heroButton button {
    background: #004FBF;
    margin-top: 2rem;
    padding: 1rem 2rem;
    border-radius: 8px;
    border: none;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
}

@media only screen and (max-width: 800px) {
    .heroSection{
        text-align: center;
        height: auto;
    }
    .herotitle{
        width: 100%;
        font-size: 2rem;
        text-align: center;
    }
    .subtitle{
        width: 100%;
    }
    .formview{
        grid-template-columns:repeat(1, 1fr);
        width: 100%;
    }
    .heroButton button{
        font-size: 1rem;
    }
}