.stats-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.stats-items-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
}

.stats-item {
    padding: 10px 20px 10px 20px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #F5F4F5;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    padding: 2rem 0;
}

.stats-item-icon {
    width: 20%;
    aspect-ratio: 1/1;
    font-size: 3rem;
    color: white;
    border: solid 5px #042b62;
    background-color: #042b62;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
}

.stats-item-heading {
    padding: 0px 0px 0px 0px;
    margin-bottom: 1rem;
    padding: 0px 0px 0px 0px;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;

}

.stats-item-content {
    color: #656565;
    /* font-family: "Poppins", Sans-serif; */
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
}

.stats-item:hover {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #042b62;
    transform: translatey(0px);
    animation: float 2s ease-in-out infinite;
}

.stats-item:hover .stats-item-icon {
    color: #042b62;
    background-color: white;
}

.slide-in {
    transform: translateX(50%);
    transition: all 1s;
    opacity: 0;
}

.slide-in.active {
    transform: translateX(0%);
    opacity: 1;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-10px);
    }

    100% {
        transform: translatey(0px);
    }
}

/* -----------------------------------------------------media query------------------------------------------------- */
@media only screen and (max-width: 600px) {
    .stats-items-container {
        grid-template-columns: 1fr;
    }
    .slide-in {
        transform: unset;
        transition: all 1s;
        opacity: unset;
    }
}