.header-section {
    position: relative;
    padding: 2rem 8rem 12rem 12rem;
    background-color: white;
    background-size: cover;
}

.header-section-background-overlay {
    background-color: #ffffff;
    opacity: 0.94;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.header-section-title {
    color: #060606;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3.5rem;
    margin-bottom: 1.5rem;
}

.header-section-title2 {
    color: #060606;
    font-size: 2.5rem;
    font-weight: bolder;
    line-height: 3.5rem;
}

.section-sub-title {
    font-size: 1.2rem;
    font-weight: 400;
}

.header-section-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 10;
}

.header-section-content {
    width: 50%;
}

.header-section-content .sectionsubText {
    color: #060606;
    font-size: 1.1rem;
}

.header-section-content-btn {
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    fill: #FFFFFF;
    color: #FFFFFF;
    background-color: #042b62;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #fcfcfc;
    border-radius: 4px 4px 4px 4px;
    transition: all 0.3s;
    padding: 1.2rem 2rem;
    cursor: pointer;
    border-radius: 50px;
}

.header-section-content-btn:hover {
    color: #042b62;
    background-color: rgba(44, 147, 245, 0);
    border-color: #042b62;
}

.header-section-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-section-image-container {
    width: 100%;
}

.outer-image-conteiner {
    width: 100%;
    position: relative;
    z-index: 88;
}

.inner-image-conteiner {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 2px;
    display: flex;
    justify-content: center;
}

.inner-image {
    width: 70%;
}

.lottie-arrow {
    width: 150px;
    position: absolute;
    top: -90%;
    left: -150%;
}

.lottie-arrow-container {
    position: relative;
}

/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */

@media only screen and (max-width:850px) {
    .header-section {
        padding: 5rem 3rem;
    }
}

@media only screen and (max-width: 600px) {
    .header-section {
        padding: 2rem 1rem;
    }

    .header-section-content {
        width: max-content;
    }

    .header-section-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .header-section-image-container {
        width: 90%;
    }

    .header-section-content-btn {
        width: 100%;
        font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    fill: #ffffff;
    color: #ffffff;
    background-color: #042b62;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #fcfcfc;
    border-radius: 30px;
    transition: all 0.3s;
    padding: 1rem 3rem;
    text-align: center;
    }

    .header-section-container {
        flex-direction: column-reverse;
        gap: 2rem;
        text-align: center;
    }

    .header-section-title {
        margin-bottom: 0rem;
    }

    .lottie-arrow {
        display: none;
    }
    .header-section-title {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
    }
    
    .header-section-title2 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: bold;
    }
    .section-sub-title{
        margin-top: 1rem;
        margin-bottom: 0;
        text-align: left;
    }
}

/* / -----------------------------------------------------------------------bounceInUp-------------------------------------------------------------- /  */
.animatedBouncInUp {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

@-webkit-keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes bounceInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateY(-30px);
    }

    80% {
        -moz-transform: translateY(10px);
    }

    100% {
        -moz-transform: translateY(0);
    }
}

@-ms-keyframes bounceInUp {
    0% {
        opacity: 0;
        -ms-transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -ms-transform: translateY(-30px);
    }

    80% {
        -ms-transform: translateY(10px);
    }

    100% {
        -ms-transform: translateY(0);
    }
}

@-o-keyframes bounceInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -o-transform: translateY(-30px);
    }

    80% {
        -o-transform: translateY(10px);
    }

    100% {
        -o-transform: translateY(0);
    }
}

@keyframes bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        transform: translateY(-30px);
    }

    80% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    -moz-animation-name: bounceInUp;
    -ms-animation-name: bounceInUp;
    -o-animation-name: bounceInUp;
    animation-name: bounceInUp;
}