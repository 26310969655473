.ReportsPage {
  background-color: rgb(230, 234, 239);
}
.ReportsPageBox {
  align-items: center;
  width: 100%;
  margin: auto;
}
.ReportsPageImg  {
  /* width: 100%; */
}
.ReportsPageImg img{
  width: 500px;
}
.ReportsPageContentTitle {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0px;
}
.ReportsPageContentText {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 15px 0px 10px 0px;
}
.ReportsPageBtn {
  margin-top: 2rem;
}
.ReportsPageBtn img{
  width: 200px;
}
.ReportsPageBtn button {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #042b62;
  border: 2px solid #042b62;
  border-radius: 30px;
  transition: all 0.3s;
  padding: 1rem 4rem;
}
.ReportsPageBtn button:hover {
  background-color: transparent;
  color: #042b62;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #042b62;
}
@media only screen and (max-width: 600px) {
  .ReportsPageImg img {
    width: 100%;
  }
  .ReportsPageContentTitle {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    letter-spacing: 0px;
  }
  .ReportsPageContentText{
    font-size: 1.1rem;
    font-weight: 500;
  }
  .ReportsPageBtn button{
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: 400;
  }
  .ReportsPageBtn{
    align-items: center;
  }
  .ReportsPageBtn img{
    width: 150px;
  } 
  .ReportsPageBtn img:last-child{
    margin-left: 1rem;
  }
}
