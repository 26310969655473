.wealth-contact-section {
    background-color: white;
}

.wealth-contact-section-container {
    background-color: #F1F9FD;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
}

.wealth-contact-section-contact-form-container {
    padding: 2rem 0;
    width: 45%;
    margin-right: 0%;
    padding-left: 8rem;
    display: flex;
    align-items: flex-end;
}

.wealth-contact-section-contact-form {
    background-color: #ffffff;
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    padding: 1.5rem 2.5rem;
    border-radius: 20px;
    flex-grow: 1;
}

.form-section-title {
    color: #060606;
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 3.5rem;
    text-align: center;
    color: #2f3436;
}

.contact-form-input-container {
    padding: 1rem 0;
}

.contact-form-label {
    font-family: "Poppins", Sans-serif;
    display: block;
    color: #313636;
    font-size: 1.2rem;
}

.contact-form-input {
    width: 100%;
    height: 48px;
    color: #000000;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    padding: 0.5rem;
}

.contact-form-input:focus {
    border:1px solid #23bbfc;
}

.invalid-border {
    border:1px solid #ff0000;
}

.contact-form-btn {
    font-family: "Raleway", Sans-serif;
    font-size: 1.2rem;
    padding: 0.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: #FFFFFF;
    background-color: #042b62;
    border: none;
    border-radius: 10px;
    margin: 1rem auto;
}

.select-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    padding: 0 0.5rem;

    font-family: "Poppins", Sans-serif;
    display: block;
    color: #313636;
    font-size: 1.2rem;
    cursor: pointer;
}

.select-input:focus,
:hover {
    outline: none;
}

/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */
@media only screen and (max-width: 850px) {
    .wealth-contact-section-contact-form-container{
        width: 50%;
        padding-left: 1rem;
    }
  }
@media only screen and (max-width: 600px) {
    .wealth-contact-section-contact-form-container{
        width: 100%;
        padding-left: 0.5rem;
    }
  }