@media (min-width: 1400px) {
    .container {
        width: 1140px!important;
    }
}
.kb {
    background-color: #042b621f;
}

.headline {
    color: #2B2C2D;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2rem;
    padding-bottom: .6rem;
}
.blogpost p {
    color: #7a7a7a;
    font-size: 1.1rem;
    font-weight: 500;
    width: 300px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.viewmore {
    background-color: #042b62;
    border-style: none;
    padding: 15px 30px;
    color: #fff;
    transition: all .4s ease;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
}
.blogimage {
    transition-duration: 0.3s
}
.blogimage:hover {
    scale: .9;
}
.blogimage{
    /* width: 100%; */
    height: 15rem;
}
.blogLink {
    text-decoration: none;
    cursor: pointer;
}