.section-title {
    color: #060606;
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 3.5rem;
    text-align: center;
    color: #2f3436;
    font-family: "Poppins", Sans-serif;
    letter-spacing: 1px;
}
.section-title2{
    color: #ffff;
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 3.5rem;
    text-align: center;
}
/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */

@media only screen and (max-width: 600px) {
    .section-title {
        font-size: 1.3rem;
    font-weight: 600;
    line-height: 2rem;
        padding: 0.7rem;
    }
}