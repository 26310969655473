.checkbox-container .custom-checkbox:checked+label:before {
    background: #042b62;
}
.checkbox-container .custom-checkbox:hover+label:before {
    background: #042b62;
    transition: .2s all ease;
}
.checkbox-container .custom-checkbox:checked+label:after {
    content: '';
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    width: 12px;
    height: 6px;
    position: absolute;
    top: 6px;
    left: 3px;
    transform: rotate(-45deg);
}
.srv-validation-message,.error{
    font-size: 12px;
}
.checkbox-label{
    font-size: 14px;
}