.button {
    background: #042b62;

    border: none;
    padding: 8px 30px;
    border-radius: 30px;
    margin: 20px 0;
    color: #fff;
    font-weight: 600;
    transition: 0.3s ease all;
    text-align: center;
    outline: none;
    font-weight: 600;
    margin-right: 25px;
    display: inline-block;
    vertical-align: middle;
}
.active {
    background: #042b62 !important;
}