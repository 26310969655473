
.my-date-picker-box {
    position: relative;
}
.my-date-picker {
    /* position: absolute; */
    
    background: #fff;
    padding: 1rem;
    border-radius: 1rem;
    /* margin-top: .5rem; */
    /* top: 0px; */
    /* left: -45px; */
    /* z-index: 999; */
}
.my-date-picker-inner {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1rem;
    
}
.my-date-picker .my-date-picker-inner div {
    font-size: 1.2rem;
    text-align: center;
    width: 1.7rem;
    line-height: 1.7rem;
    color: #000;
    cursor: pointer;
}
.date-text {
    font-size: 1.2rem;
    padding-bottom: 1rem;
    color: #888;
}
.date-text p {
    margin-bottom: .6rem;
    text-align: center;
}
.date-text p:nth-child(2) {
    font-size: 1.2rem;
    color: #000;
}
.my-date-picker-active {
    background-color: #888;
    color: #fff!important;
    background-color: #216ba5;
    border-radius: 0.3rem;
}
.confirm-btn {
    font-size: 1rem;
    width: 100%;
    background-color: #042b62;
    border: 0px;
    padding-top: .6rem;
    padding-bottom: .6rem;
    color: #fff;
}
.close-btn {
    font-size: 0.8rem;
    position: absolute;
    right: 15px;
    cursor: pointer;
}